import React, { useState, useEffect, useRef } from "react";
import {
    useTheme,
    Grid,
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import UserService from "../../services/UserService";
import { RoleType, User } from "../../interfaces/user/User";
import { isBrowser } from "react-device-detect";
import UserMgmtDialog from "./UserMgmtDialog";
import GenericDropdown from "../DeviceManagement/GenericDropdown";

export interface OrgEntry {
    organization_id: string;
    organization_name: string;
    organization_type: string;
    organization_parent_id: string | null;
}

interface OrgEntryWithChildren extends OrgEntry {
    children: OrgEntryWithChildren[];
}

interface OrganizationOption {
    value: string;
    label: string;
}

export const UserManagement: React.FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [cookies] = useCookies(["access_token"]);
    const [showUserDialog, setShowUserDialog] = useState<boolean>(false);
    const [showOK, setShowOK] = useState<boolean>(false);
    const dialogMode = useRef<string>("add");
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
    const token = cookies.access_token as string;
    const [users, setUsers] = useState<User[]>([]);
    const [userLevels, setUserLevels] = useState<RoleType[]>([]);
    const [organizations, setOrganizations] = useState<OrgEntry[]>([]);
    const [filterText, setFilterText] = useState<string>('');
    const [selectedUser, setSelectedUser] = useState<User | undefined>();
    const [fetchUsers, setFetchUsers] = useState<number>(0);
    const [selectedOrganization, setSelectedOrganization] = useState<string>("");

    const handleRowClick = (user: User) => {
        dialogMode.current = "edit";
        if (user.phone === undefined) {
            user.phone = '';
        }
        setSelectedUser(user);
        setShowUserDialog(true);
    };

    const handleCloseDialog = () => {
        setShowUserDialog(false);
    };

    const triggerRefresh = () => {
        setFetchUsers(fetchUsers + 1);
    };

    const sortUsersByLastName = (users: User[]) => {
        return [...users].sort((a, b) => {
            const lastNameA = a.last_name || "";
            const lastNameB = b.last_name || "";
            return lastNameA.localeCompare(lastNameB, undefined, { sensitivity: 'base' });
        });
    };

    useEffect(() => {
        let isMounted = true;

        UserService.getAllHierarchyUsers(token).then((response: any) => {
            if (isMounted) {
                const sortedUsers = sortUsersByLastName(response.users);
                setUsers(sortedUsers);
            }
        }).catch((error: any) => {
            console.log("Fetch error response:", error);
        });

        return () => {
            isMounted = false;
        };
    }, [fetchUsers, token]);

    useEffect(() => {
        let isMounted = true;

        UserService.getMyProfile(token).then((response: any) => {
            if (isMounted) {
                console.log("my profile:", response);
                setUserLevels(response.userLevels);
                setIsSuperAdmin(response.myprofile.is_superadmin);

                const orgData: OrgEntry[] = response.organizations.map((org: any) => ({
                    organization_id: org.organization_id,
                    organization_name: org.organization_name,
                    organization_type: org.organization_type,
                    organization_parent_id: org.organization_parent_id,
                }));
                setOrganizations(orgData);
            }
        }).catch((error: any) => {
            console.log("Fetch error response:", error);
        });

        return () => {
            isMounted = false;
        };
    }, [token]);

    const addUserButton = () => {
        dialogMode.current = "add";
        setShowUserDialog(true);
    };

    const handleCloseOK = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== "backdropClick") {
            setShowUserDialog(false);
            setShowOK(false);
        }
    };

    const fontVariant: "body2" | "caption" = isBrowser ? "body2" : "caption";

    const handleOrganizationChange = (value: string) => {
        setSelectedOrganization(value);
    };

    const filteredUsers = users.filter(user => {
        const searchText = filterText.toLowerCase();
        const organizationMatch = selectedOrganization === "" || user.organization === selectedOrganization;
        return organizationMatch && (
            user.last_name?.toLowerCase().includes(searchText) ||
            user.first_name?.toLowerCase().includes(searchText) ||
            user.username?.toLowerCase().includes(searchText) ||
            user.email?.toLowerCase().includes(searchText) ||
            user.phone?.toLowerCase().includes(searchText) ||
            (user.is_admin ? 'Yes' : 'No').toLowerCase().includes(searchText)
        );
    });

    // Build the hierarchical tree and create indented options
    const orgMap: { [key: string]: OrgEntryWithChildren } = organizations.reduce(
        (map: { [key: string]: OrgEntryWithChildren }, org: OrgEntry) => {
            map[org.organization_id] = { ...org, children: [] };
            return map;
        },
        {}
    );

    let rootOrgs: OrgEntryWithChildren[] = [];

    organizations.forEach((org: OrgEntry) => {
        const parentId = org.organization_parent_id;
        if (parentId && orgMap[parentId]) {
            orgMap[parentId].children.push(orgMap[org.organization_id]);
        } else {
            // If no parent or parent not found, it's a root org
            rootOrgs.push(orgMap[org.organization_id]);
        }
    });

    // Function to traverse the tree and create options with indentation
    function traverseOrg(
        orgs: OrgEntryWithChildren[],
        level = 0
    ): OrganizationOption[] {
        let result: OrganizationOption[] = [];
        orgs.sort((a, b) => a.organization_name.localeCompare(b.organization_name));
        orgs.forEach((org) => {
            result.push({
                value: org.organization_id,
                label: `${'\u00A0\u00A0\u00A0\u00A0'.repeat(level)}${org.organization_name}`,
            });
            if (org.children && org.children.length > 0) {
                result = result.concat(traverseOrg(org.children, level + 1));
            }
        });
        return result;
    }

    // Create indented organization options for GenericDropdown
    const organizationOptions: OrganizationOption[] = [
        { value: "", label: t("All") },
        ...traverseOrg(rootOrgs)
    ];

    // Function to flatten the hierarchical organizations with indented names
    function flattenOrgHierarchy(
        orgs: OrgEntryWithChildren[],
        level = 0,
        result: OrgEntry[] = []
    ): OrgEntry[] {
        orgs.sort((a, b) => a.organization_name.localeCompare(b.organization_name));
        orgs.forEach((org) => {
            const indentedName = `${'\u00A0\u00A0\u00A0\u00A0'.repeat(level)}${org.organization_name}`;
            result.push({
                ...org,
                organization_name: indentedName,
                // Remove the 'children' property since OrgEntry doesn't have it
            });
            if (org.children && org.children.length > 0) {
                flattenOrgHierarchy(org.children, level + 1, result);
            }
        });
        return result;
    }

    // Create indented organizations for UserMgmtDialog
    const indentedOrganizations: OrgEntry[] = flattenOrgHierarchy(rootOrgs);

    console.log("organizationOptions:", organizationOptions);
    console.log("indentedOrganizations:", indentedOrganizations);

    return (
        <Box sx={{ mx: 2, mt: 2 }}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <TextField
                        label={t("Search")}
                        variant="outlined"
                        value={filterText}
                        InputLabelProps={{ shrink: true }}
                        placeholder={t("type_to_filter")}
                        onChange={(e) => setFilterText(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                </Grid>
                <Grid item xs>
                    <Typography variant="h4" align="center">
                        {t("user_management")}
                    </Typography>
                </Grid>
                {isSuperAdmin && (
                    <Grid item>
                        <GenericDropdown
                            options={organizationOptions}
                            selectedValue={selectedOrganization}
                            onChange={handleOrganizationChange}
                            title={t("customer_org")}
                            sx={{ minWidth: 200 }}
                        />
                    </Grid>
                )}
            </Grid>

            <Dialog open={showOK} fullWidth={true} maxWidth={"md"}>
                <DialogTitle> {t("success")}</DialogTitle>
                <DialogContent>
                    <Typography>{t("user_added_successfully")}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseOK}> {t("close")}</Button>
                </DialogActions>
            </Dialog>

            <UserMgmtDialog
                userData={selectedUser}
                open={showUserDialog}
                handleClose={handleCloseDialog}
                userLevels={userLevels}
                dialogMode={dialogMode.current}
                organizations={indentedOrganizations} // Pass indented organizations
                triggerRefresh={triggerRefresh}
                is_superadmin={isSuperAdmin}
            />

            <Grid item xs={12} md={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ padding: "16px 6px" }}>{t("lastname")}</TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>{t("firstname")}</TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>{t("username")}</TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>{t("email_address")}</TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>{t("phone")}</TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>{t("role")}</TableCell>
                                {isSuperAdmin && <TableCell sx={{ padding: "16px 6px" }}>{t("customer_org")}</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredUsers.map((user: User) => (
                                <TableRow
                                    key={user.username}
                                    hover
                                    onClick={() => handleRowClick(user)}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        "&:nth-of-type(odd)": { background: theme.palette.info.dark },
                                    }}
                                >
                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.last_name}</Typography>
                                    </TableCell>

                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.first_name}</Typography>
                                    </TableCell>

                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.username}</Typography>
                                    </TableCell>

                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.email}</Typography>
                                    </TableCell>

                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.phone}</Typography>
                                    </TableCell>

                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{t(user.role?.name || '')}</Typography>
                                    </TableCell>

                                    {isSuperAdmin && <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.organization_name}</Typography>
                                    </TableCell>}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid container mt={2}>
                <Grid item xs={6} md={4}>
                    <Button variant="outlined" onClick={addUserButton}>
                        {t("add_user")}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};
