import React from 'react';
import { Paper, Typography, List, IconButton, Box, Divider } from '@mui/material';
import { useDrop } from 'react-dnd';
import EditIcon from '@mui/icons-material/Edit';
import DeviceItem from './DeviceItem';
import { Device } from "../../interfaces/Device";
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';

interface GroupDevicesProps {
    devices: Device[];
    selectedGroupName: string;
    groupContact: string;
    handleDropToGroup: (device: Device) => void;
    handleRemoveFromGroup: (device: Device) => void;
    isAdmin: boolean;
    handleEditGroup: () => void;
    allowDragAndDrop: boolean;
}

const GroupDevices: React.FC<GroupDevicesProps> = ({
    devices,
    allowDragAndDrop,
    groupContact,
    selectedGroupName,
    handleDropToGroup,
    handleRemoveFromGroup,
    isAdmin,
    handleEditGroup,
}) => {
    const theme = useTheme();

    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: allowDragAndDrop ? 'DEVICE' : [],
            drop: allowDragAndDrop
                ? (item: Device) => {
                      handleDropToGroup(item);
                  }
                : undefined,
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
            }),
        }),
        [allowDragAndDrop, handleDropToGroup]
    );

    // Sort devices by device_id in a case-insensitive manner
    const sortedDevices = [...devices].sort((a, b) =>
        a.device_id.toLowerCase().localeCompare(b.device_id.toLowerCase())
    );

    return (
        <Paper
            elevation={3}
            sx={{
                padding: 2,
                backgroundColor: isOver
                    ? theme.palette.action.hover
                    : theme.palette.background.paper,
                color: theme.palette.text.primary,
            }}
            ref={allowDragAndDrop ? drop : undefined}
        >
            <Box display="flex" flexDirection="column">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">
                        {selectedGroupName || t('group_devices')}
                    </Typography>
                    {isAdmin && (
                        <IconButton size="small" onClick={handleEditGroup} color="inherit">
                            <EditIcon fontSize="small" />
                        </IconButton>
                    )}
                </Box>
                <Typography>
                    {t('contact')}: {groupContact}
                </Typography>
            </Box>
            <Divider sx={{ my: 1 }} />
            <List>
                {sortedDevices.map((device) => (
                    <DeviceItem
                        key={device.device_id}
                        device={device}
                        onRemove={handleRemoveFromGroup}
                        onClick={() => {}}
                    />
                ))}
            </List>
        </Paper>
    );
};

export default GroupDevices;
