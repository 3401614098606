import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, Paper, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Typography } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import GroupTree from './GroupTree';
import AvailableDevices from './AvailableDevices';
import GroupDevices from './GroupDevices';
import DeviceGroupsDialog from './DeviceGroupsDialog';
import VICUService from "../../services/VICUService";
import useFetch from "../../hooks/useFetch";
import { useCookies } from "react-cookie";
import { Device } from "../../interfaces/Device"
import { useTranslation } from "react-i18next";


export interface TreeNode {
    group_id: string;
    group_name: string;
    primary_contact: string;
    children?: TreeNode[];
    level: 'region' | 'group';
    devices?: Device[]; // Add devices to TreeNode interface
}

//const initialData: TreeNode[] = initialDataJson as TreeNode[];

const MonitorGroupManager: React.FC = () => {
    const isAdmin = true;
    const [groupData, setGroupData] = useState<TreeNode[]>([]);
    const [poolDevices, setPoolDevices] = useState<Device[]>([]);
    const [groupDevices, setGroupDevices] = useState<{ [key: string]: Device[] }>({});
    const [selectedGroup, setSelectedGroup] = useState<string>(""); // Default to no selection
    const selectedGroupRef = useRef<string>(""); // Default to no selection
    const [selectedGroupName, setSelectedGroupName] = useState<string>(''); // Default to no selection
    const [selectedContact, setSelectedContact] = useState<string>(''); // Default to no selection
    const [expanded, setExpanded] = useState<string[]>([]); // No default expansion
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
    const [newGroupName, setNewGroupName] = useState<string>('');
    const [newContact, setNewContact] = useState<string>('');
    const [editGroupName, setEditGroupName] = useState<string>('');
    const [editContact, setEditContact] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
    const [deviceGroupsDialogOpen, setDeviceGroupsDialogOpen] = useState<boolean>(false);
    const [groupIdNameMap, setGroupIdNameMap] = useState<{ [key: string]: string }>({});
    const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState<boolean>(false);
    const [dialogLevel, setDialogLevel] = useState<'region' | 'group'>('region');
    const [dialogParentId, setDialogParentId] = useState<string>('');
    const [enableDragAndDrop, setEnableDragAndDrop] = useState<boolean>(true);
    const [cookies] = useCookies(["access_token"]);
    //const theme = useTheme();
    const { t } = useTranslation();


    /*
     * Fetch the monitor group list
     */
    const {
        data: initialMonitorGroupList,
        //loading: monitorGroupLoading,
        //error: monitorGroupError,
        //refresh: refreshMonitorGroups,
    } = useFetch({
        url: VICUService.getAllGroups(),
        access_token: cookies.access_token,
    });

    const [monitorGroupRegister, setMonitorGroupRegister] = useState<{ [key: string]: Device[] }>({});

    const constructMonitorGroupRegistry = (nodes: TreeNode[]): { [key: string]: Device[] } => {
        const registry: { [key: string]: Device[] } = {};
    
        const traverseNodes = (nodeList: TreeNode[]) => {
            nodeList.forEach(node => {
                registry[node.group_id] = node.devices || [];
                if (node.children && node.children.length > 0) {
                    traverseNodes(node.children);
                }
            });
        };
    
        traverseNodes(nodes);
        return registry;
    };
    
    // Load initial data once
    useEffect(() => {
        if (initialMonitorGroupList) {
            console.log("initialMonitorGroupList:", initialMonitorGroupList);

            // The pool devices are in the initial monitor group list
            const pooldevs = getPoolDevices(initialMonitorGroupList);
            setPoolDevices(pooldevs);

            // Filter out the entry with group_name='syspool'
            const modifiedGroupList = initialMonitorGroupList.filter((group:any) => group.group_name !== 'syspool');

            setGroupData(modifiedGroupList);

            // from GroupData we create a dictionary of group_id and the attached devices. We use this dict
            // to keep track of which devices are assigned to each of the monitor groups.
            const register = constructMonitorGroupRegistry (modifiedGroupList);
            setMonitorGroupRegister (register)
            //console.log("pool devices:", pooldevs);
            //console.log("Monitor group list:", modifiedGroupList);
            //console.log("Monitor group registry:", register);
        }
    }, [initialMonitorGroupList]);



    const getPoolDevices = (data: any[]): Device[] => {
        const poolGroup = data.find(group => group.group_name === 'syspool');
        return poolGroup ? poolGroup.devices : [];
    };

    useEffect(() => {
        selectedGroupRef.current = selectedGroup;
    }, [selectedGroup]);

    useEffect(() => {
        if (groupData === null) {
            return;
        }
        //console.log("Process groupData:", groupData);
        const generateGroupIdNameMap = (nodes: TreeNode[], map: { [key: string]: string } = {}): { [key: string]: string } => {
            nodes.forEach(node => {
                map[node.group_id] = node.group_name;
                if (node.children) {
                    generateGroupIdNameMap(node.children, map);
                }
            });
            return map;
        };
        const map = generateGroupIdNameMap(groupData);
        setGroupIdNameMap(map);
    }, [groupData]);



    useEffect(() => {
        if (groupData.length > 0) {
            // Find the first region
            const firstRegion = groupData.find(group => group.level === 'region');
            if (firstRegion) {
                handleNodeSelect(null, firstRegion.group_id);
                setExpanded([firstRegion.group_id]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupData]);

    const handleOpenDialog = (level: 'region' | 'group', parentId: string) => {
        //console.log("handleOpenDialog parent:", parentId);
        setNewGroupName('');
        setNewContact('')
        setDialogLevel(level);
        setDialogParentId(parentId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setNewGroupName('');
        setNewContact('')
    };

    const handleAddGroupOrRegion = () => {
        //console.log("handleAddGroupOrRegion:", newGroupName, dialogLevel, dialogParentId);
        if (!newGroupName.trim()) return;

        // Create the VICU in the back-end. If this is a "region" then there is no parent node. Otherwise
        // create a monitor group in the currently selected region.

        const saveData = {
            name: newGroupName,
            vicu_type: 'continuous',
            contact: newContact,
            parm_granularity: 3600,
            level: dialogLevel,
            parent_id: dialogParentId
        }
        //console.log("Save VICU:", saveData)
        VICUService.addVICU(saveData, cookies.access_token)
            .then((res: any) => {
                //console.log("Return data:", res)
                const newGroupId = res.data.vicu_id
                const newContact = res.data.contact_info
                const newGroup: TreeNode = { group_id: newGroupId, group_name: newGroupName, level: dialogLevel, primary_contact: newContact };
                const addGroupToNode = (nodes: TreeNode[]): TreeNode[] => {
                    if (dialogLevel === 'region') {
                        return [...nodes, newGroup];
                    }

                    return nodes.map(node => {
                        if (node.group_id === dialogParentId && dialogLevel === 'group') {
                            return {
                                ...node,
                                children: node.children ? [...node.children, newGroup] : [newGroup],
                            };
                        } else if (node.children) {
                            return { ...node, children: addGroupToNode(node.children) };
                        }
                        return node;
                    });
                };

                setGroupData(addGroupToNode(groupData));
                setExpanded([...expanded, newGroupId]);

            }).catch((err: any) => {
                console.log(err);
            });

        handleCloseDialog();
    };

    const handleDeleteGroupOrRegion = () => {
        if (!selectedGroup) return;
        //console.log("Delete:", selectedGroup)

        VICUService.deleteVICU(selectedGroup, cookies.access_token)
            .then((res: any) => {
                //console.log("delete result:", res);
                const deleteGroupFromNode = (nodes: TreeNode[]): TreeNode[] => {
                    return nodes.filter(node => node.group_id !== selectedGroup).map(node => {
                        if (node.children) {
                            return { ...node, children: deleteGroupFromNode(node.children) };
                        }
                        return node;
                    });
                };

                setGroupData(deleteGroupFromNode(groupData));
                setSelectedGroup(""); // Clear selection after deletion
            })
            .catch((err: any) => {
                console.log(err);
            });

        setSelectedGroupName(''); // Clear selected group name
        setSelectedContact('')
    };

    const handleNodeToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };


    const handleNodeSelect = (event: React.SyntheticEvent | null, nodeId: string) => {
        setSelectedGroup(nodeId);
    
        //console.log("Selected group:", nodeId, groupData);
        // if the groupId exists in the gropuData array where the item.group_id matches groupId, then do nothing and just return
        if (groupData.some(item => item.group_id === nodeId)) {
            //console.log("Group exists in groupData:", nodeId);
            setEnableDragAndDrop (false)
        } else {
            setEnableDragAndDrop(true);
        }
        
        if (monitorGroupRegister[nodeId]) {
            setGroupDevices(prevGroupDevices => ({
                ...prevGroupDevices,
                [nodeId]: monitorGroupRegister[nodeId]
            }));
        }
    
        const findGroup = (nodes: TreeNode[]): TreeNode | null => {
            for (const node of nodes) {
                if (node.group_id === nodeId) {
                    return node;
                } else if (node.children) {
                    const foundGroup = findGroup(node.children);
                    if (foundGroup) return foundGroup;
                }
            }
            return null;
        };
    
        const group = findGroup(groupData);
        if (group) {
            setSelectedGroupName(group.group_name);
            setSelectedContact(group.primary_contact);
        }
    
        if (!expanded.includes(nodeId)) {
            setExpanded([...expanded, nodeId]);
        }
    };
    

    const handleDropToGroup = (device: Device) => {
        const groupId = selectedGroupRef.current;
        if (!groupId) return;


        VICUService.manageGroupDevices(groupId, {
                type: "attach",
                devices: [{ "device_id": device.device_id }]
            }, cookies.access_token).then((res: any) => {
                //console.log("Attach to group result:", res);

                // Update groupDevices state
                setGroupDevices(prevGroupDevices => {
                    const updatedGroupDevices = { ...prevGroupDevices };
                    if (!updatedGroupDevices[groupId]) {
                        updatedGroupDevices[groupId] = [];
                    }
                    if (!updatedGroupDevices[groupId].some(d => d.device_id === device.device_id)) {
                        updatedGroupDevices[groupId].push(device);
                    }
                    return updatedGroupDevices;
                });
            
                // Update monitorGroupRegister state
                setMonitorGroupRegister(prevRegister => {
                    const updatedRegister = { ...prevRegister };
                    if (!updatedRegister[groupId]) {
                        updatedRegister[groupId] = [];
                    }
                    if (!updatedRegister[groupId].some(d => d.device_id === device.device_id)) {
                        updatedRegister[groupId].push(device);
                    }
                    return updatedRegister;
                });

            })
            .catch((err: any) => {
                console.log("error:", err);
            });
    

    };
    

    const handleRemoveFromGroup = (device: Device) => {
        const groupId = selectedGroupRef.current;
        if (!groupId) return;
    
        VICUService.manageGroupDevices(groupId, {
                type: "detach",
                devices: [{ "device_id": device.device_id }]
            }, cookies.access_token).then((res: any) => {
                //console.log("Detach from group result:", res);
                // Update groupDevices state
                setGroupDevices(prevGroupDevices => {
                    const updatedGroupDevices = { ...prevGroupDevices };
                    if (updatedGroupDevices[groupId]) {
                        updatedGroupDevices[groupId] = updatedGroupDevices[groupId].filter(d => d.device_id !== device.device_id);
                    }
                    return updatedGroupDevices;
                });

                // Update monitorGroupRegister state
                setMonitorGroupRegister(prevRegister => {
                    const updatedRegister = { ...prevRegister };
                    if (updatedRegister[groupId]) {
                        updatedRegister[groupId] = updatedRegister[groupId].filter(d => d.device_id !== device.device_id);
                    }
                    return updatedRegister;
                });            })
            .catch((err: any) => {
                console.log("error:", err);
            });
    
        
    };
    
    const getFilteredDevices = (): Device[] => {
        if (!poolDevices) {
            return []
        }
        if (!selectedGroup) return poolDevices;
        const groupDeviceIds = groupDevices[selectedGroup]?.map(device => device.device_id) || [];
        return poolDevices.filter((device: Device) => !groupDeviceIds.includes(device.device_id));
    };

    const handleDeviceClick = (device: Device) => {
        //setSelectedDevice(device);
        //setDeviceGroupsDialogOpen(true);
    };

    const handleCloseDeviceGroupsDialog = () => {
        setDeviceGroupsDialogOpen(false);
        setSelectedDevice(null);
    };

    const getDeviceGroups = (device: Device): string[] => {
        return Object.keys(groupDevices)
            .filter(groupId => groupDevices[groupId].some(d => d.device_id === device.device_id))
            .map(groupId => groupIdNameMap[groupId]);
    };

    const handleGroupClickInDialog = (groupName: string) => {
        const groupId = Object.keys(groupIdNameMap).find(id => groupIdNameMap[id] === groupName);
        if (!groupId) return;
        setDeviceGroupsDialogOpen(false);
        handleNodeSelect(null, groupId); // Trigger state updates as if clicked in tree
    };

    const handleEditGroup = () => {
        setEditGroupName(selectedGroupName);
        setEditContact (selectedContact)
        setEditDialogOpen(true);
    };

    const handleSaveEditGroup = async () => {
        if (!selectedGroup || !editGroupName.trim()) return;

        try {
            const res = await VICUService.editVICU(selectedGroup, editGroupName.trim(), editContact.trim(), 10, 'continuous', cookies.access_token);
            //console.log("Return data:", res);

            // Update group data with the new name
            const updateGroupName = (nodes: TreeNode[]): TreeNode[] => {
                return nodes.map(node => {
                    if (node.group_id === selectedGroup) {
                        return { ...node, group_name: res.data.vicu_name, primary_contact:res.data.contact_info }; // Ensure we update the correct key
                    } else if (node.children) {
                        return { ...node, children: updateGroupName(node.children) };
                    }
                    return node;
                });
            };

            const updatedGroupData = updateGroupName(groupData);

            // Update state
            setGroupData(updatedGroupData);
            setGroupIdNameMap(prevMap => ({ ...prevMap, [selectedGroup]: res.data.vicu_name }));
            setSelectedGroupName(res.data.vicu_name);
            setSelectedContact (res.data.contact_info)

            setEditDialogOpen(false);
        } catch (err) {
            console.log(err);
        }
    };


    const handleDeleteGroup = () => {
        setDeleteConfirmDialogOpen(true);
    };

    const confirmDeleteGroup = () => {
        handleDeleteGroupOrRegion();
        setEditDialogOpen(false);
        setDeleteConfirmDialogOpen(false);
    };

    const handleCloseEditDialog = () => {
        setEditDialogOpen(false);
    };

    const handleCloseDeleteConfirmDialog = () => {
        setDeleteConfirmDialogOpen(false);
    };

    const handleAddRegionClick = () => {
        handleOpenDialog('region', '');  // No parent for region
    };

    const handleAddGroupClick = (regionId: string) => {
        handleOpenDialog('group', regionId);  // Pass the ID of the selected region
    };
    //console.log("Tree data:", groupData)

    return (
        <DndProvider backend={HTML5Backend}>
            <Box sx={{ flexGrow: 1, p: 2 }}>
                <Grid
                    container
                    justifyContent="center"
                >
                    <Grid item>
                    <Typography variant="h4" sx={{ mb: 2 }}>{t ("monitor_group_management") }</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Paper elevation={3} sx={{ padding: 0 }}>
                            <GroupTree
                                data={groupData}
                                expanded={expanded}
                                selectedGroup={selectedGroup}
                                handleNodeToggle={handleNodeToggle}
                                handleNodeSelect={handleNodeSelect}
                                handleAddRegion={handleAddRegionClick}
                                handleAddGroup={handleAddGroupClick}
                                isAdmin={isAdmin}
                            />

                        </Paper>
                        {isAdmin && (<Button sx={{mt:2}} variant="outlined"  onClick={handleAddRegionClick} color="primary">
                            { t("add_region") }
                        </Button>)}
                    </Grid>
                    <Grid item xs={4}>
                        <GroupDevices
                            devices={selectedGroup ? groupDevices[selectedGroup] || [] : []}
                            selectedGroupName={selectedGroupName} // Pass as string
                            groupContact={selectedContact}
                            allowDragAndDrop = { enableDragAndDrop}
                            handleDropToGroup={handleDropToGroup}
                            handleRemoveFromGroup={handleRemoveFromGroup}
                            isAdmin={isAdmin} // Pass isAdmin
                            handleEditGroup={handleEditGroup} // Pass handleEditGroup
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <AvailableDevices
                            devices={getFilteredDevices()} // Use the filtered devices
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            handleRemoveFromGroup={handleRemoveFromGroup}
                            handleDeviceClick={handleDeviceClick}
                        />
                    </Grid>
                </Grid>

                <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                    <DialogTitle>
                        Add New {dialogLevel === 'region' ? 'Region' : 'Group'}
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Name"
                            fullWidth
                            value={newGroupName}
                            onChange={(e) => setNewGroupName(e.target.value)}
                        />
                        <Grid item>
                            <TextField
                                label={t("contact")}
                                id="contact"
                                value={newContact}
                                fullWidth
                                onChange={(e) => setNewContact(e.target.value)}
                                sx={{ mt: 2 }} // Adds top margin
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <Button onClick={handleCloseDialog} >
                                {t("cancel")}
                            </Button>
                            <Button onClick={handleAddGroupOrRegion} color="primary">
                                {t("save")}
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>

                <Dialog open={editDialogOpen} onClose={handleCloseEditDialog} maxWidth="md" fullWidth>
                    <DialogTitle>Edit Group Name</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Group Name"
                            fullWidth
                            value={editGroupName}
                            onChange={(e) => setEditGroupName(e.target.value)}
                        />
                        <Grid item>
                            <TextField
                                label="Contact"
                                id="contact"
                                value={editContact}
                                fullWidth
                                onChange={(e) => setEditContact(e.target.value)}
                                sx={{ mt: 2 }} // Adds top margin
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <Button onClick={handleCloseEditDialog} color="primary">
                                {t("cancel")}
                            </Button>
                            <Button onClick={handleDeleteGroup} color="warning">
                                {t("delete")}
                            </Button>
                            <Button onClick={handleSaveEditGroup} color="primary">
                                {t("save")}
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>

                <Dialog open={deleteConfirmDialogOpen} onClose={handleCloseDeleteConfirmDialog}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Are you sure you want to delete this group? This action cannot be undone.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteConfirmDialog} color="primary">
                            Cancel
                        </Button>
                        <Button variant="contained"  onClick={confirmDeleteGroup} color="warning">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <DeviceGroupsDialog
                    open={deviceGroupsDialogOpen}
                    onClose={handleCloseDeviceGroupsDialog}
                    selectedDevice={selectedDevice}
                    getDeviceGroups={getDeviceGroups}
                    handleGroupClickInDialog={handleGroupClickInDialog}
                />

            </Box>
        </DndProvider>
    );
};

export default MonitorGroupManager;
