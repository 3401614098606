import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { patientVitalsColor } from "../../utils/data/PatientVitalsColor";
import { useTranslation } from "react-i18next";

interface ParameterValue {
  value?: string | number;
  unit?: string;
  text?: string;
}

interface ParameterBlock {
  param_name: string;
  value?: ParameterValue;
  subparameters?: Record<string, ParameterValue>;
  active_alarm: boolean;
}

interface Props {
  parameters: ParameterBlock[];
  connectStatus: string;
  compact?: boolean;
  theme?: string;
}

export const CompactMonitor = ({ parameters, connectStatus, theme = "default" }: Props) => {
  const [colors, setColors] = useState<Record<string, string>>(patientVitalsColor(theme));
  const { t } = useTranslation();
  const isConnected = connectStatus === "connected";

  const [parmRR, setParmRR] = useState<ParameterValue>();
  const [parmCO2, setParmCO2] = useState<ParameterValue>();
  const [parmSPO2, setParmSPO2] = useState<ParameterValue>();
  const [parmHR, setParmHR] = useState<ParameterValue>();

  useEffect(() => {
    setColors(patientVitalsColor(theme));
  }, [theme]);

  useEffect(() => {
    const rr = parameters.find((param) => param.param_name === "Resp")?.subparameters?.rr;
    setParmRR(rr);

    const hr = parameters.find((param) => param.param_name === "HR")?.value;
    setParmHR(hr);

    const spo2 = parameters.find((param) => param.param_name === "SpO2")?.subparameters?.sp;
    setParmSPO2(spo2);

    const co2 = parameters.find((param) => param.param_name === "Resp")?.subparameters?.co2;
    setParmCO2(co2);
  }, [parameters]);

  const getColor = (paramName: string) => colors[paramName] || "white";

  const renderValue = (paramValue: string | number | undefined, fallback = "--") =>
    paramValue && isConnected ? paramValue : fallback;

  return (
    <Grid container margin={0} padding={0}    alignItems="center"  height={"20px"}>

      <Grid item xs={3} sx={{ border: '1px solid white', background: getColor("HR"), paddingLeft:"5px", paddingRight:"5px" }}>
        <Grid container justifyContent="space-between">
          <Typography variant="h6"  color="white">{t("HR")}</Typography>
          <Typography variant="h6" color="white">{renderValue(parmHR?.value)}</Typography>
        </Grid>
      </Grid>

      <Grid item xs={3} sx={{ border: '1px solid white', background: getColor("SpO2"), paddingLeft:"5px", paddingRight:"5px" }}>
        <Grid container justifyContent="space-between">
          <Typography variant="h6" color="white">{t("SpO2")}</Typography>
          <Typography variant="h6" color="white">{renderValue(parmSPO2?.value)}</Typography>
        </Grid>
      </Grid>

      <Grid item xs={3} sx={{ border: '1px solid white', background: getColor("Resp"), paddingLeft:"5px", paddingRight:"5px" }}>
        <Grid container justifyContent="space-between">
          <Typography variant="h6" color="white">{t("RR")}</Typography>
          <Typography variant="h6" color="white">{renderValue(parmRR?.value)}</Typography>
        </Grid>
      </Grid>

      <Grid item xs={3} sx={{ border: '1px solid white', background: getColor("Resp"), paddingLeft:"5px", paddingRight:"5px" }}>
        <Grid container justifyContent="space-between">
          <Typography variant="h6" color="white">{t("CO2")}</Typography>
          <Typography variant="h6" color="white">{renderValue(parmCO2?.value)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
