import { useState, useEffect, useContext } from "react";
import {
    useTheme,
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Checkbox,
    Typography,
    DialogTitle,
    TextField,
    FormHelperText
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import UserService from "../../services/UserService";
import { checkEmail } from "../../pages/Login/ForgotPassword";
import CustomSelect from '../../components/CustomSelect';
import { RoleType, User } from "../../interfaces/user/User";
import { OrgEntry } from "./UserManagement";
import React from 'react';
import { UserContext } from "../../context/UserContext";

const userTypesAvailable : {
    "sysadmin" : string[];
    "domain"   : string[];
    "customer" : string[];
} = {

    sysadmin : ["serveradmin", "domainadmin"],
    domain   : ["domainadmin"],
    customer : ["orgadmin","staffuser", "physician"]
}
// Key type definition
type UserTypeKey = keyof typeof userTypesAvailable;
// Function to get the users of a specific type
function getUsersByType(userType: UserTypeKey): string[] {
    return userTypesAvailable[userType];
}

interface UserAccessDialogProps {
    userData?: User;
    open: boolean;
    dialogMode: string;
    handleClose: () => void;
    userLevels: RoleType[]
    organizations: OrgEntry[];
    triggerRefresh: () => void;
    is_superadmin: boolean;
}

const UserMgmtDialog: React.FC<UserAccessDialogProps> = ({ userData, is_superadmin, triggerRefresh, open, userLevels, handleClose, organizations, dialogMode }) => {
    //console.log ("UserMgmtDialog:", userData, userLevels)
    const { t } = useTranslation();
    const theme = useTheme();
    const userCtx = useContext(UserContext);
    const [cookies] = useCookies(["access_token"]);
    const [lastName, setLastName] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [sendEmailState, setSendEmailState] = useState(false);
    const [selectedUserLevel, setSelectedUserLevel] = useState<RoleType>();
    const [organization, setOrganization] = useState<string>();
    const [initialPassword, setInitialPassword] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(false);
    const [disableRoles, setDisableRoles] = useState(false);
    const [availableUserLevels, setAvailableUserLevels] = useState <RoleType[]>([])
    const [showRoles, setShowRoles] = useState (userCtx?.user?.username !== userData?.username);

    useEffect(() => {
        if (organizations.length === 1) {
            setOrganization(organizations[0].organization_id);
        }
       // console.log ("Organizations:", organizations)
    }, [organizations]);

    useEffect (() => {

    }, [userLevels])

    useEffect(() => {
        if (dialogMode === "edit" && userData && userLevels.length > 0) {
            setUserName(userData.username || '');
            setLastName(userData.last_name || '');
            setFirstName(userData.first_name || '');
            setEmail(userData.email || '');
            setPhone(userData.phone || '');
            setSelectedUserLevel(userData.role);
            setOrganization(userData.organization || '');
            const userRoleType = userData.role?.roletype || '';
            const userLevelFound = userLevels.some(role => role.roletype === userRoleType);
            setDisableRoles(!userLevelFound);
            setDisableRoles(false);
        } else {
            resetForm();
        }
    }, [dialogMode, userData, userLevels]);

    useEffect(() => {
        if (userCtx?.user?.username !== userData?.username) {
            setShowRoles(true);
        } else {
            setShowRoles(false);
        }
    }, [userCtx?.user?.username, userData?.username]);


    // When the organization changes, the types of users that can be created changes also. For example,
    // it is not possible to create a domain admin user in a customer organization.
    // The available user levels will be in userLevels, and the actual ones we will use will be a subset
    // of that
    useEffect (() => {
        //console.log ("New organization selected:", organization)
        const orgRec = organizations.find (org => org.organization_id === organization)
        const orgType = orgRec?.organization_type;

        if (orgType) {
            const validEntries = getUsersByType(orgType as UserTypeKey);
            //console.log ("Valid user types to create are:", validEntries)
            const filteredUserLevels = userLevels.filter(userLevel => validEntries.includes(userLevel.roletype));
            //console.log ("Available user levels are", filteredUserLevels)
            setAvailableUserLevels (filteredUserLevels)
        }
    }, [organization, organizations, userLevels])

    
    const resetForm = () => {
        setUserName('');
        setLastName('');
        setFirstName('');
        setEmail('');
        setPhone('');
        setSelectedUserLevel(undefined);
        setOrganization('');
        setInitialPassword('');
        setIsValid(false);
        setDisableRoles(false);
    };

    const validatePassword = (password: string): boolean => {
        const minLength = 8;
        const hasUpper = /[A-Z]/.test(password);
        const hasLower = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        return password.length >= minLength && hasUpper && hasLower && hasNumber;
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newPass = event.target.value;
        setInitialPassword(newPass);
        setIsValid(validatePassword(newPass) || newPass.trim().length === 0);
    };

    const handleSendEmailChange = (event: any) => {
        setSendEmailState(event.target.checked);
    };

    const checkRequired = () => {
        //console.log ("checkRequired:", userData, selectedUserLevel)
        if (!checkEmail(email)) {
            return false;
        }
        if (lastName === "" || firstName === "" || userName === "") {
            return false;
        }

        // Only applicable for new user
        if (dialogMode === "add") {
            if (!(isValid || (initialPassword.trim().length === 0 && sendEmailState))) {
                return false;
            }
            // well then we're good to save the new user.
            return true;
        }


        // The rest of the logic only runs for "edit" and we need to have at least one field
        // which has changed.

        if ((selectedUserLevel?.roletype !== userData?.role?.roletype) ||
            (email !== userData?.email) ||
            (lastName !== userData?.last_name) ||
            (firstName !== userData?.first_name) ||
            (phone !== userData?.phone)
        ){
            return true;
        }
        return false;
    };

    const santizeInput = (i: string) => {
        return i.replace(/[^a-zA-Z0-9-_]/g, '');
    };

    const handleUserNameChange = (uname: string) => {
        setUserName(santizeInput(uname));
    };

    const handleUserLevelChanged = (level: string) => {
        const roleEntry = userLevels.find(role => role.roletype === level);
        if (roleEntry) {
            setSelectedUserLevel(roleEntry);
        } else {
            console.error('Role not found for the given level:', level);
        }
    };

    const handleCancelUser = () => {
        handleClose();
    };


    const [showDeleteWarning, setShowDeleteWarning] = useState(false);

    const handleDeleteUser = () => {
        setShowDeleteWarning(true);
    };
    
    const confirmDeleteUser = async () => {
        if (!userData?.username) {
            return;
        }
        UserService.deleteUser(userData.username, cookies.access_token).then(() => {
            triggerRefresh();
            handleClose();
        });
        setShowDeleteWarning(false);
    };
    
    const handleCancelDelete = () => {
        setShowDeleteWarning(false);
    };
    


    const handleSaveUser = async () => {
        const newUser: User = {
            last_name: lastName,
            first_name: firstName,
            username: userName,
            email: email,
            phone: phone,
            title: "",
            send_invite: sendEmailState,
            organization: organization,
            hierarchy_id: organization,
            roletype: selectedUserLevel?.roletype,
            passwd: initialPassword,
            role: selectedUserLevel
        };
        console.log ("Save user:", newUser )

        if (dialogMode === "add") {
            UserService.createUser(newUser, cookies.access_token).then((res: any) => {
                if (res?.status === 200) {
                    handleClose();
                    triggerRefresh();
                } else {
                    // An error occurred
                    console.error("error result:", res.response);
                    alert('An error occurred while creating the user\n' + res.response.data.response);
                }
            }).catch((error: any) => {
                console.error("exception:", error);
            })
        }

        if (dialogMode === "edit") {
            UserService.modifyUser(newUser, cookies.access_token).then((res: any) => {
                if (res?.status === 200) {
                    handleClose();
                    triggerRefresh();
                } else {
                    // An error occurred
                    console.error("error result:", res.response);
                    alert('An error occurred while modifying the user\n' + res.response.data.response);
                }
            }).catch(console.error).finally(() => {
                setSaveInProgress(false);
            });
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth={"sm"}>
            <DialogTitle sx={{ marginBottom: "10px" }}>
                {dialogMode === "add" ? t("add_user") : t("edit_user")}
            </DialogTitle>
            <DialogContent>
                <Grid item xs={12}>
                    {dialogMode === "add" && is_superadmin && (
                        <CustomSelect
                            label="Organization"
                            options={organizations}
                            valueExtractor={(org) => org.organization_id}
                            descriptionExtractor={(org) => org.organization_name}
                            value={organization || ''}
                            onChange={setOrganization}
                        />
                    )}
                    {showRoles && (availableUserLevels.length > 0) && <CustomSelect
                        label={ t("user_role")}
                        options={availableUserLevels}
                        disabled={disableRoles}
                        
                        valueExtractor={(role) => role.roletype}
                        descriptionExtractor={(role) => role.name}
                        value={selectedUserLevel?.roletype || ""}
                        onChange={handleUserLevelChanged}
                    />}
                    <TextField
                        id="last_name"
                        label={t("lastname")}
                        type="text"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        sx={{
                            input: {
                                color: theme.palette.text.primary,
                                background: theme.palette.background.default,
                            },
                            label: {
                                color: theme.palette.text.primary,
                            },
                        }}
                    />
                    <TextField
                        id="first_name"
                        label={t("firstname")}
                        type="text"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        sx={{
                            input: {
                                color: theme.palette.text.primary,
                                background: theme.palette.background.default,
                            },
                            label: {
                                color: theme.palette.text.primary,
                            },
                        }}
                    />
                    <TextField
                        id="username"
                        label={t("username")}
                        type="text"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={userName}
                        disabled={dialogMode === "edit"}
                        onChange={(e) => handleUserNameChange(e.target.value)}
                        sx={{
                            input: {
                                color: theme.palette.text.primary,
                                background: theme.palette.background.default,
                            },
                            label: {
                                color: theme.palette.text.primary,
                            },
                        }}
                    />
                    <TextField
                        id="email"
                        label={t("email_address")}
                        type="email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        margin="dense"
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            input: {
                                color: theme.palette.text.primary,
                                background: theme.palette.background.default,
                            },
                            label: {
                                color: theme.palette.text.primary,
                            },
                        }}
                    />
                    <TextField
                        id="phone"
                        label={t("phone")}
                        type="text"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        sx={{
                            input: {
                                color: theme.palette.text.primary,
                                background: theme.palette.background.default,
                            },
                            label: {
                                color: theme.palette.text.primary,
                            },
                        }}
                    />
                    {dialogMode === "add" && (
                        <div>
                            <TextField
                                id="password"
                                label={t("initial_password")}
                                type="text"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                onChange={handlePasswordChange}
                                sx={{
                                    input: {
                                        color: theme.palette.text.primary,
                                        background: theme.palette.background.default,
                                    },
                                    label: {
                                        color: theme.palette.text.primary,
                                    },
                                }}
                            />
{!isValid && initialPassword.length > 0 && (
    <FormHelperText error>
        {t("invalid_pwd")}
    </FormHelperText>
)}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{ color: theme.palette.primary.main }}
                                        checked={sendEmailState}
                                        onChange={handleSendEmailChange}
                                    />
                                }
                                label={t("send_email_invite")}
                            />
                        </div>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Button onClick={handleCancelUser}>
                            {t("cancel")}
                        </Button>
                    </Grid>
                    {dialogMode === "edit" && (
                        <Grid item>
                            <Button color="warning" onClick={handleDeleteUser}>
                                {t("delete")}
                            </Button>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            disabled={!checkRequired() || saveInProgress}
                            onClick={handleSaveUser}
                        >
                            {t("save")}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>

            <Dialog open={showDeleteWarning} onClose={handleCancelDelete}>
                <DialogTitle>{t("Delete User")}</DialogTitle>
                <DialogContent>
                    <Typography>{t("Are you sure you want to delete this user? This cannot be undone.")}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete}>{t("cancel")}</Button>
                    <Button onClick={confirmDeleteUser} color="warning">{t("delete")}</Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    );
    
}

export default UserMgmtDialog;
