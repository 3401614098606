import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";



export interface LiveParmProps {
	parm: any;
	parm_name: string;
	connectStatus: string;
	isVideoOn?: boolean;
	eqpAlarmsEnabled: boolean;
    showUnit?: boolean;
    fontCorrection?: number;
}




export const ParmDisplay = ({
	parm,
	parm_name,
	connectStatus,
	isVideoOn,
	eqpAlarmsEnabled,
    showUnit = false,
    fontCorrection = 0
}: LiveParmProps) => {
	//console.log (connectStatus)
	const { t } = useTranslation();

	// Different parameters have slightly different parameter structures. Deal with that here.
	var parm_unit = "-";
	var parm_val = "-";
	var active_alarm = false;

	if (!parm) {
		parm_unit = "";
		parm_val = "-";
	} else {
		switch (parm_name) {
			case "HR": {
				parm_unit = parm.unit;
				parm_val = parm.value;
				active_alarm = parm.active_alarm;
				break;
			}
			case "SpO2": {
				parm_unit = parm.sp ? parm.sp.unit : parm.unit;
				parm_val = parm.sp ? parm.sp.value : parm.value;
				active_alarm = parm.active_alarm;
				break;
			}
			case "Resp": 
            case "RR" :{
				parm_unit = parm.rr.unit;
				parm_val = parm.rr.value;
				active_alarm = parm.active_alarm;
				break;
			}
			case "EtCO2": {
				parm_unit = parm.co2.unit;
				parm_val = parm.co2.value;
				active_alarm = parm.active_alarm;
				break;
			}

			case "IBP1": {
				parm_unit = parm.systole.unit;
				parm_val = parm.systole.value;
				active_alarm = parm.active_alarm;
				break;
			}
			case "Temp": {
				parm_unit = parm.t1 ? parm.t1.unit : parm.unit;
				parm_val = parm.t1 ? parm.t1.value : parm.value;
				//var parm_num = +parm_val; // convert from string to Number
				//if (isNaN (parm_num)) {
				//	parm_num = -1
				//} else {
				//	parm_num = +parm_num.toFixed(1)
				//}
				//				if (isNaN(+parm_val)) {
				//					parm_val = parm.t1.value;
				//				} else {
				//					const parm_num = Number(parm.t1.value);
				//					parm_val = parm_num.toFixed(1);
				//				}
				//parm_val = parm_val.toFixed (1)
				active_alarm = parm.active_alarm;
				break;
			}
		}
		active_alarm = active_alarm && eqpAlarmsEnabled
	}


    // Define base font sizes and adjust based on fontCorrection
    const nameFontSize = `calc(var(--name-fontsize) - ${fontCorrection}px)`;
    const valueFontSize = `calc(var(--value-fontsize) - ${fontCorrection}px)`;
    const unitFontSize = `calc(var(--unit-fontsize) - ${fontCorrection}px)`;

	if (isVideoOn) {
		return (
			<Grid
				container
				item
				xs={3}
				sx={{
					padding: 0.5,
					backgroundColor: "black",
					borderColor: "red",
					mt: -0.5,
				}}
				direction="row"
			>
				<Grid
					item
					className={
						connectStatus === "connected"
							? "parameter-name-" + parm_name
							: "parameter-name-disconnected"
					}
				>
					
                    
                    { parm_name && t(parm_name)}
					<span
						style={{ marginLeft: 4 }}
						className={
							connectStatus === "connected"
								? "parameter-unit-" + parm_name
								: "parameter-unit-disconnected"
						}
					>
						{parm_unit}
					</span>
				</Grid>
				<Grid
					item
					xs={3}
					className={
						connectStatus === "connected"
							? "parameter-value-" + parm_name
							: "parameter-value-disconnected"
					}
					style={{ textAlign: "right" }}
				>
					<div
						className={
							active_alarm
								? "parameter-value-alarm"
								: "parameter-value-normal"
						}
					>
						{parm_val}
					</div>
				</Grid>
			</Grid>
		);
	}

	//console.log (parm_name, active_alarm)
	return (
		<Grid
			container
			direction="row"
			style={{ marginBottom: "1px", height: "100%" }}
		>
			<Grid
				item
				xs={8}
				className={
					connectStatus === "connected"
						? "parameter-name-" + parm_name
						: "parameter-name-disconnected"
				}
                style={{ fontSize: nameFontSize }} // Apply dynamic font size

			>
                {/* Parameter Name */}
                {parm_name && t(parm_name)}

                {/* Parameter Unit */}
				{ showUnit && <span
					className={
						connectStatus === "connected"
							? "parameter-unit-" + parm_name
							: "parameter-unit-disconnected"
					}
                    style={{ fontSize: unitFontSize }} // Apply dynamic font size
                    >
					{" "}
					{parm_unit}
				</span>}
			</Grid>

			{/* Parameter Value */}
			<Grid
				item
				xs={4}
				className={
					connectStatus === "connected"
						? "parameter-value-" + parm_name
						: "parameter-value-disconnected"
				}
                style={{ textAlign: "right", fontSize: valueFontSize }} // Apply dynamic font size
                >
				<div
					className={
						active_alarm
							? "parameter-value-alarm"
							: "parameter-value-normal"
					}
				>
					{parm_val}
				</div>
			</Grid>
		</Grid>
	);
}; // ParmDisplay