import React, { useEffect, Fragment, SyntheticEvent } from 'react';
import { Grid, Box, Tabs, Tab, Stack } from "@mui/material";
import { useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import GenericDropdown from "../../pages/DeviceManagement/GenericDropdown";

interface Props {
  tabNames: string[];
  tabComponents?: React.ReactNode[];
  value: number;
  onTabChange: (newValue: number) => void;
  regionOptions?: { value: string; label: string }[];
  selectedRegion?: string;
  regionDropdownTitle?: string;
  onRegionChange?: (value: string) => void;
  filterOptions?: { value: number; label: string }[];
  selectedFilter?: string;
  filterDropdownTitle?: string;
  onFilterChange?: (value: string) => void;
}

export default function CustomTabs({
  tabNames,
  tabComponents,
  value,
  onTabChange,
  filterOptions,
  selectedFilter,
  filterDropdownTitle,
  onFilterChange,
  regionOptions,
  regionDropdownTitle,
  selectedRegion,
  onRegionChange,
}: Props) {
  const handleChange = (_: SyntheticEvent, newValue: number) => {
    onTabChange(newValue);
  };
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    const state = location.state as { startingTab?: number };
    if (state && state.startingTab !== undefined) {
      onTabChange(state.startingTab);
    }
  }, [location.state, onTabChange]);

  const handleRegionChange = (value: string) => {
    if (onRegionChange) {
      onRegionChange(value);
    }
  };

  const handleFilterChange = (value: string) => {
    if (onFilterChange) {
      onFilterChange(value);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              '& .MuiTab-root': {
                minWidth: 'auto',
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
              },
            }}
          >
            {tabNames.map((tab, i) => {
              const isSysPoolTab = tab.toLowerCase() === 'manage groups';
              return (
                <Tab
                  key={tab}
                  label={
                    <span
                      style={{
                        color: isSysPoolTab ? 'orange' : theme.palette.text.primary,
                        fontSize: isBrowser ? "inherit" : "12px",
                      }}
                    >
                      {tab}
                    </span>
                  }
                  {...a11yProps(i)}
                />
              );
            })}
          </Tabs>
        </Grid>

        {/* Dropdowns with spacing */}
        <Grid item style={{ marginTop: '10px' }}>
          <Stack direction="row" spacing={2} alignItems="center">
            {filterDropdownTitle && (
              <GenericDropdown
                options={filterOptions || []}
                selectedValue={selectedFilter || ''}
                onChange={handleFilterChange}
                title={filterDropdownTitle}
                sx={{ minWidth: 200 }}
              />
            )}
            {regionDropdownTitle && (
              <GenericDropdown
                options={regionOptions || []}
                selectedValue={selectedRegion || ''}
                onChange={handleRegionChange}
                title={regionDropdownTitle}
                sx={{ minWidth: 200 }}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      {tabComponents?.map((tab, i) => {
        return (
          <TabPanel key={i} value={value} index={i}>
            {tab}
          </TabPanel>
        );
      })}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Fragment>{children}</Fragment>
        </Box>
      )}
    </div>
  );
}
