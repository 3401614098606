import React, { memo } from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
    options: { value: string | number; label: string }[];
    selectedValue: string;
    onChange: (value: string) => void;
    title: string;
    disabled?: boolean;
    sx?: object; // Add sx property to the Props interface
}

const GenericDropdown = ({
    options,
    selectedValue,
    onChange,
    title,
    disabled = false,
    sx,
}: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const handleChange = (event: SelectChangeEvent) => {
        onChange(event.target.value as string);
    };

    return (
        <FormControl fullWidth sx={{ ...sx, backgroundColor: theme.palette.background.paper }}>
            <InputLabel
                shrink={true} // Ensure label is always shrunk
                sx={{
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.background.paper,
                    paddingLeft: 1,
                    paddingRight: 1,
                }}
            >
                {t(title)}
            </InputLabel>
            <Select
                value={selectedValue}
                label={t(title)}
                onChange={handleChange}
                disabled={disabled}
                sx={{
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.background.paper, // Set the background color
                    '& .MuiInputLabel-root.Mui-focused': {
                        backgroundColor: theme.palette.background.paper, // Ensure the label background matches the form background
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default memo(GenericDropdown);
