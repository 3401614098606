import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "./AlarmProfile.css";
import { Dispatch, SetStateAction } from "react";

interface Props {
	profile: any;
	alarms: any;
	isEditingThresholds: boolean;
	setAlarms: any;
	setIsEditingThresholds: Dispatch<SetStateAction<boolean>>;
}

export const AlarmProfile = ({
	profile,
	alarms,
	isEditingThresholds,
	setAlarms,
	setIsEditingThresholds,
}: Props) => {
	// console.log ("AlarmProfile:", profile)
	//const theme = useTheme();
	const { t } = useTranslation();

	const theme = useTheme();

	if (!profile) {
		return<></>;
	}
	return (

		<>
			<TableContainer sx={{ mt: 2, maxWidth: "800px" }} component={Paper}>
			<Table
					aria-label="compact table"
					sx={{
						tableLayout:"fixed",
						"& .MuiTableCell-root": {
							padding: "4px 8px", // Reduce padding for cells
							fontSize: "0.9rem", // Reduce font size for cells
						},
					}}
				>
					<TableHead
						sx={{
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.common.white,
							"& .MuiTableCell-root": {
								padding: "4px 8px", // Reduce padding for header cells
								fontSize: "0.8rem", // Reduce font size for header cells
							},
						}}
					>
						<TableRow>
							<TableCell sx={{ width: "100px"}}>{t("code")}</TableCell>
							<TableCell sx={{ width: "200px"}}>{t("parameter")}</TableCell>
							<TableCell>{t("Critical")}</TableCell>
							<TableCell>{t("Major")}</TableCell>
							<TableCell>{t("Minor")}</TableCell>
							<TableCell>{t("Normal")}</TableCell>
							<TableCell>{t("Minor")}</TableCell>
							<TableCell>{t("Major")}</TableCell>
							<TableCell>{t("Critical")}</TableCell>
						</TableRow>
					</TableHead>


					<TableBody>
						{alarms.map((parameter: any, index: number) => (
							<TableRow
								key={parameter.code}
								sx={{
									"&:nth-of-type(odd)": {
										backgroundColor:
											theme.palette.action.hover,
									},
									"&:nth-of-type(even)": {
										backgroundColor:
											theme.palette.background.default,
									},
									"& .MuiTableCell-root": {
										padding: "0px 0px", // Reduce padding for body cells
										fontSize: "0.75rem", // Reduce font size for body cells
			overflow: "hidden", // Hide overflow content
			textOverflow: "ellipsis", // Add ellipsis for overflowed text
			whiteSpace: "nowrap", // Prevent text from wrapping
		},
								}}
							>
								<TableCell>{parameter.code}</TableCell>
								<TableCell>{parameter.name}</TableCell>
								<TableCell
									align="center"
									sx={{
										color: theme.palette.common.black,
										backgroundColor: "#E59D88",
									}}
								>
									{isEditingThresholds && (
										<Grid
											container
											item
											direction="row"
											justifyContent="space-evenly"
											margin={0}
											padding={0}
										>
											<Typography>≤</Typography>
											<TextField
												sx={{
													width: 50,
												}}
												inputProps={{
													style: {
														padding: 0,
														fontSize: 14,
														textAlign: "center",
														color: theme.palette
															.common.black,
													},
												}}
												defaultValue={
													parameter?.low.critical
												}
												onChange={(e) => {
													parameter.low.critical =
														e.target.value === ""
															? null
															: parseInt(
																	e.target
																		.value
															  );
													setAlarms([...alarms]);
												}}
											/>
										</Grid>
									)}

									{!isEditingThresholds &&
										parameter?.low.critical &&
										"≤ " + parameter.low.critical}
								</TableCell>
								<TableCell
									align="center"
									sx={{
										color: theme.palette.common.black,
										backgroundColor: "#F4CF95",
									}}
								>
									{isEditingThresholds && (
										<Grid
											container
											direction="row"
											alignItems="center"
											justifyContent="space-evenly"
										>
											<Typography>≤</Typography>
											<TextField
												sx={{
													width: 50,
												}}
												inputProps={{
													style: {
														color: theme.palette
															.common.black,
														fontSize: 14,
														padding: 0,
														textAlign: "center",
													},
												}}
												defaultValue={
													parameter?.low.major
												}
												onChange={(e) => {
													parameter.low.major =
														e.target.value === ""
															? null
															: parseInt(
																	e.target
																		.value
															  );
													setAlarms([...alarms]);
												}}
											/>
										</Grid>
									)}
									{!isEditingThresholds &&
										parameter.low.major &&
										"≤ " + parameter.low.major}
								</TableCell>
								<TableCell
									align="center"
									sx={{
										color: theme.palette.common.black,
										backgroundColor: "#FCF2B8",
									}}
								>
									{isEditingThresholds && (
										<Grid
											container
											direction="row"
											alignItems="center"
											justifyContent="space-evenly"
										>
											<Typography>≤</Typography>
											<TextField
												sx={{
													width: 50,
												}}
												inputProps={{
													style: {
														color: theme.palette
															.common.black,
														fontSize: 14,
														padding: 0,
														textAlign: "center",
													},
												}}
												defaultValue={
													parameter?.low.minor
												}
												onChange={(e) => {
													parameter.low.minor =
														e.target.value === ""
															? null
															: parseInt(
																	e.target
																		.value
															  );
													setAlarms([...alarms]);
												}}
											/>
										</Grid>
									)}
									{!isEditingThresholds &&
										parameter.low.minor &&
										"≤ " + parameter.low.minor}
								</TableCell>
								<TableCell align="center">-</TableCell>
								<TableCell
									align="center"
									sx={{
										color: theme.palette.common.black,
										backgroundColor: "#FCF2B8",
									}}
								>
									{isEditingThresholds && (
										<Grid
											container
											direction="row"
											alignItems="center"
											justifyContent="space-evenly"
										>
											<Typography>≥</Typography>
											<TextField
												sx={{
													width: 50,
												}}
												inputProps={{
													style: {
														color: theme.palette
															.common.black,
														padding: 0,
														fontSize: 14,
														textAlign: "center",
													},
												}}
												defaultValue={
													parameter?.high.minor
												}
												onChange={(e) => {
													parameter.high.minor =
														e.target.value === ""
															? null
															: parseInt(
																	e.target
																		.value
															  );
													setAlarms([...alarms]);
												}}
											/>
										</Grid>
									)}
									{!isEditingThresholds &&
										parameter.high.minor &&
										"≥ " + parameter.high.minor}
								</TableCell>
								<TableCell
									align="center"
									sx={{
										color: theme.palette.common.black,
										backgroundColor: "#F4CF95",
									}}
								>
									{isEditingThresholds && (
										<Grid
											container
											direction="row"
											alignItems="center"
											justifyContent="space-evenly"
										>
											<Typography>≥</Typography>
											<TextField
												sx={{
													width: 50,
												}}
												inputProps={{
													style: {
														color: theme.palette
															.common.black,
														fontSize: 14,
														padding: 0,
														textAlign: "center",
													},
												}}
												defaultValue={
													parameter?.high.major
												}
												onChange={(e) => {
													parameter.high.major =
														e.target.value === ""
															? null
															: parseInt(
																	e.target
																		.value
															  );
													setAlarms([...alarms]);
												}}
											/>
										</Grid>
									)}
									{!isEditingThresholds &&
										parameter.high.major &&
										"≥ " + parameter.high.major}
								</TableCell>
								<TableCell
									align="center"
									sx={{
										color: theme.palette.common.black,
										backgroundColor: "#E59D88",
									}}
								>
									{isEditingThresholds && (
										<Grid
											container
											direction="row"
											alignItems="center"
											justifyContent="space-evenly"
										>
											<Typography>≥</Typography>
											<TextField
												sx={{
													width: 60,
												}}
												inputProps={{
													style: {
														color: theme.palette
															.common.black,
														fontSize: 14,
														padding: 0,
														textAlign: "center",
													},
												}}
												defaultValue={
													parameter?.high.critical
												}
												onChange={(e) => {
													parameter.high.critical =
														e.target.value === ""
															? null
															: parseInt(
																	e.target
																		.value
															  );
													setAlarms([...alarms]);
												}}
											/>
										</Grid>
									)}
									{!isEditingThresholds &&
										parameter.high.critical &&
										"≥ " + parameter.high.critical}
								</TableCell>
								
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
