import { Waveform } from "../../../interfaces/vitals/Waveform";

export interface DrawInfo {
    color: string;
    y_scalefactor: number;
    range_min: number;
    range_max: number;
  }
  
const arrAvg = (arr: number[]) =>
    arr.reduce((a: number, b: number) => a + b, 0) / arr.length;
  
const ECG_WAVEFORMS = [
    "ECG_L1",
    "ECG_L2",
    "ECG_L3",
    "ECG_V",
    "ECG_AVR",
    "ECG_AVL",
    "ECG_AVF",
    "ECG_Defi",
  ];


  export const waveDrawInfo_Touch7: { [name: string]: DrawInfo } = {
    ECG_L1:   { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-hr"), y_scalefactor: 1, range_min: -1, range_max: 1 },
    ECG_L2:   { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-hr"), y_scalefactor: 2, range_min: -1, range_max: 1 },
    ECG_L3:   { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-hr"), y_scalefactor: 2, range_min: -1, range_max: 1 },
    ECG_Defi: { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-hr"), y_scalefactor: 2, range_min: -1, range_max: 1 },
    SpO2:     { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-spo2"), y_scalefactor: 0.3, range_min: -100, range_max: 100 },
    Resp:     { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-resp"), y_scalefactor: 0.25, range_min: -127, range_max: 128 },
    IBP1:     { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-ibp1"), y_scalefactor: 0.5, range_min: 0, range_max: 180 },
    EtCO2:    { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-resp"), y_scalefactor: 2, range_min: 0, range_max: 4 },
  };

  export const waveDrawInfo_Recobro: { [name: string]: DrawInfo } = {
    ECG_L1:   { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-hr"), y_scalefactor: 1, range_min: -1, range_max: 1 },
    ECG_L2:   { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-hr"), y_scalefactor: 2, range_min: -1, range_max: 1 },
    ECG_L3:   { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-hr"), y_scalefactor: 2, range_min: -1, range_max: 1 },
    ECG_AVR:  { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-hr"), y_scalefactor: 2, range_min: -1, range_max: 1 },
    ECG_AVL:  { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-hr"), y_scalefactor: 2, range_min: -1, range_max: 1 },
    ECG_AVF:  { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-hr"), y_scalefactor: 2, range_min: -1, range_max: 1 },
    ECG_V:    { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-hr"), y_scalefactor: 2, range_min: -1, range_max: 1 },
    SpO2:     { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-spo2"), y_scalefactor: 0.3, range_min: -100, range_max: 100 },
    Resp:     { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-resp"), y_scalefactor: 0.25, range_min: -127, range_max: 128 },
    IBP1:     { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-ibp1"), y_scalefactor: 0.5, range_min: 0, range_max: 180 },
    IBP2:     { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-ibp1"), y_scalefactor: 0.5, range_min: 0, range_max: 180 },
    EtCO2:    { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-resp"), y_scalefactor: 2, range_min: 0, range_max: 100 },
    ACT:      { color: getComputedStyle(document.documentElement).getPropertyValue("--parm-color-act"), y_scalefactor: 0.5, range_min: 0, range_max: 24 },
  };

const waveDrawInfoMap: { [key: string]: { [name: string]: DrawInfo } } = {
  "Defigard Touch 7": waveDrawInfo_Touch7,
  "Recobro v2": waveDrawInfo_Recobro,
};

export interface WaveformPixel {
    value: number;
    color: string;
}

/*
 * Convert sample buffer entries to pixels - scale according to number of pixels per second.
 */
export const makePixels = (
    waveform: Waveform,
    pixels_per_second: number,
    swimlane_height: number,
    deviceModel: string     // "Recobro v2" or "Defigard Touch 7"
  ) => {
    console.log ("deviceModel:", deviceModel)
    const pixels: WaveformPixel[] = [];
    const samplerate: number = waveform.samplerate;
    const correct_mean: boolean = ECG_WAVEFORMS.includes(waveform.name);
    const samples: number[] = waveform.samples;
    const sampleValidity: number[] = waveform.sampleValidity;
    const myDrawInfo = waveDrawInfoMap[deviceModel] ? waveDrawInfoMap[deviceModel][waveform.name] : waveDrawInfoMap["Defigard Touch 7"][waveform.name];
    const swimlane_center = swimlane_height * 0.5;
    //console.log (waveform)
    // Pick out which pixels we want based on the viewport resolution.
    // We take every n'th sample calculated by n = samples per second / pixels per second
    // That is the sample rate divided by the pixels per second resolution of the viewport.
  
    const meanval: number = correct_mean ? arrAvg(samples) : 0;
    const value_range = myDrawInfo.range_max - myDrawInfo.range_min;
    const scalefactor = swimlane_height / value_range;
  
    const n_samples = waveform.samples.length; // number of samples in buffer
    const samples_per_pixel = samplerate / pixels_per_second; // sample/sec * sec/pixel = sample/pixel
    const pixels_per_sample = 1 / samples_per_pixel;
    const n_pixels = Math.floor(n_samples * pixels_per_sample); // pixels = samples
  
    // Map samples to pixel values
    var pixel_index = 0;

    while (pixel_index++ < n_pixels) {
      var sample_index = Math.floor(pixel_index * samples_per_pixel);
      if (sample_index >= n_samples) {
        // Can happen for the last pixel due to floating point arithmetic
        // console.log("Sample index overrun", sample_index, n_samples);
        sample_index = n_samples - 1;
      }
  
      const sample_val = samples[Math.floor(sample_index)] - meanval;
      const valid_val  = sampleValidity.length > 0 ? sampleValidity[Math.floor(sample_index)] : 0;

      if (isNaN(sample_val)) {
        //console.log ("NAN detected:", samples[Math.floor(sample_index)], sample_index, meanval, samples)
      }
      var pixel_y = sample_val * scalefactor + swimlane_center;
      var d0 = 0;
      switch (waveform.name) {
        case "ECG_L1":
        case "ECG_L2":
        case "ECG_L3":
        case "ECG_V":
        case "ECG_AVR":
        case "ECG_AVL":
        case "ECG_AVF":
        case "SpO2":
        case "Resp":
        case "EtCO2":
        case "ACT":
        case "ECG_Defi": {
          d0 = pixel_y;
          const pixelColor = (valid_val !== 0) ? 'grey' : myDrawInfo.color
          pixels.push({ value: d0, color: pixelColor});
          break;
        }
  
        case "IBP1":
        case "IBP2": {
          //const sample_val = samples[Math.floor(sample_index)] - myDrawInfo.range_min;
          //const sample_fraction = sample_val / value_range;
          //d0 = swimlane_height - sample_fraction  * swimlane_height ;
          const sample_val =
            samples[Math.floor(sample_index)] - meanval - myDrawInfo.range_min;
          const px_y = sample_val * scalefactor;
          pixels.push({ value: px_y, color: valid_val ? 'grey' : myDrawInfo.color});
          break;
        }
  
        default: {
          d0 = (sample_val - myDrawInfo.range_min) * scalefactor;
          pixels.push({ value: -d0, color: valid_val ? 'grey' : myDrawInfo.color});
        }
      }
    }

  
    //console.log ("Made num pixels:", n_px_made)
    return pixels;
  }; // makePixels