import React, { useState, useEffect } from 'react';
import { TableRow, TableCell, Link, Box } from '@mui/material';
import { Device } from "../../interfaces/Device";
import useSentioSocket from '../../hooks/useSentioSocket';
import QueryStatsIcon from "@mui/icons-material/QueryStats";

import { HEARTBEAT_GREEN, HEARTBEAT_YELLOW, HEARTBEAT_GREY, 
        HEARTBEAT_GREEN_COLOR, HEARTBEAT_YELLOW_COLOR, HEARTBEAT_GREY_COLOR, HEARTBEAT_NODATA_COLOR } from './MissionCentral';

interface DeviceRowProps {
  index: number;
  isVisible: boolean;
  device: Device;
  selectedDevice: Device | null;
  activityStatus: Record<string, string>;
  handleRowClick: (device: Device) => void;
  updateActivityStatus: (device_id: string, timestamp: string) => void;
}

export const getStatusDot = (timestamp: string | undefined) => {
  const dotStyle = { fontSize: '1.5em' };

  if (!timestamp) return <span style={{ ...dotStyle, color: HEARTBEAT_NODATA_COLOR }}>○</span>;

  const currentTime = new Date().getTime();
  const activityTime = new Date(timestamp).getTime();
  const timeDiffSeconds = (currentTime - activityTime) / 1000;

  if (timeDiffSeconds <= HEARTBEAT_GREEN) {
    return <span style={{ ...dotStyle, color: HEARTBEAT_GREEN_COLOR }}>●</span>;
  } else if (timeDiffSeconds <= HEARTBEAT_YELLOW) {
    return <span style={{ ...dotStyle, color: HEARTBEAT_YELLOW_COLOR }}>●</span>;
  } else if (timeDiffSeconds <= HEARTBEAT_GREY) {
    return <span style={{ ...dotStyle, color: HEARTBEAT_GREY_COLOR }}>●</span>;
  } else {
    return <span style={{ ...dotStyle, color: HEARTBEAT_NODATA_COLOR }}>○</span>;
  }
};

export const formatTimestamp = (timestamp: string | undefined) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  return date.toLocaleString(undefined, {
    hour12: false,
    second: '2-digit',
    minute: '2-digit',
    hour: '2-digit',
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
};

const DeviceRow: React.FC<DeviceRowProps> = ({ index, device, isVisible, selectedDevice, activityStatus, handleRowClick, updateActivityStatus }) => {
  const [dataPacket, setDataPacket] = useState<any>(null);

  useSentioSocket({ device, setDataPacket, mode: "heartbeat" });

  useEffect(() => {
    if (dataPacket && dataPacket.device_id && dataPacket.timestamp) {
      updateActivityStatus(dataPacket.device_id, dataPacket.timestamp);
    }
    //console.log("DeviceRow: dataPacket", dataPacket);
  }, [dataPacket, updateActivityStatus]);

  const statusDot = getStatusDot(activityStatus[device.device_id]);
  const shouldShowTimestamp = !(statusDot.props.style.color === 'green');

  if (!isVisible) {
    return <></>
  }
  return (
    <TableRow
      key={device.device_id}
      onClick={() => handleRowClick(device)}
      selected={selectedDevice?.device_id === device.device_id}
    >
      <TableCell sx={{ width: '5%' }}>{index + 1}</TableCell>
      <TableCell sx={{ width: '25%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Link href={`/wavemonitor/${device.device_id}`} target="_blank" rel="noopener noreferrer">
            {device.device_id}
          </Link>
          <Link href={`/caresummary/${device.device_id}`} target="_blank" rel="noopener noreferrer">
            <QueryStatsIcon
              style={{
                cursor: "pointer",
                fontSize: 22,
              }}
            />
          </Link>
        </Box>
      </TableCell>
      <TableCell sx={{ width: '20%' }}>{device.device_model}</TableCell>
      <TableCell sx={{ width: '20%' }}>{device.nickname}</TableCell>
      <TableCell sx={{ width: '10%', textAlign: 'center' }}>
        {statusDot}
      </TableCell>
      <TableCell sx={{ width: '20%' }}>
        {shouldShowTimestamp ? formatTimestamp(activityStatus[device.device_id]) : '\u00A0'}
      </TableCell>
    </TableRow>
  );
};


export default DeviceRow;
