

import "./LiveUnit.css";
import { Grid } from "@mui/material";
import { PatientVitals } from "../../../interfaces/vitals/PatientVitals";
import { ParmDisplay } from "./ParmDisplay";
import { useEffect, useState } from "react";

// note: this does not seem to be used in the LiveWaveforms screen.

export interface LiveParmsProps {
	availableParms: string[];
	parms: PatientVitals;
	connectStatus: string;
	isVideoOn?: boolean;
	eqpAlarmsEnabled: boolean;
}

export const LiveUnitParameters = ({
	parms,
	availableParms,
	connectStatus,
	isVideoOn,
	eqpAlarmsEnabled
}: LiveParmsProps) => {
	//console.log ("LiveUnitParameters availableParms:", availableParms)
	const [fontCorrection, setFontCorrection] = useState(0);
	//console.log ("LiveUnitParameters parms:", parms)
	const selectedParams = ["HR", "SpO2", "Resp", "IBP1", "Temp"];
	//console.log ("LiveUnitParameters rx params:", parms)

	// The size of the parameter display depends on the number of parameters to be plotted.
	useEffect(() => {
		const n_params = availableParms.length;

		var corr = 0;
		var r = document.querySelector(":root");
		if (r) {
			var rs = getComputedStyle(r);
			const w: string = rs
				.getPropertyValue("--fontize-delta")
				.replace("px", "");
				corr = +w;
		}

		if (n_params > 4) {
			setFontCorrection (corr)
		} else {
			setFontCorrection (0);
		}

	}, [availableParms]);


	if (isVideoOn) {
		return (
			<Grid container>
				{selectedParams.map(
					(param) =>
						availableParms.includes(param) && (
							<ParmDisplay
								key={param}
								parm_name={param}
								// @ts-ignore
								parm={parms[param]}
								connectStatus={connectStatus}
								isVideoOn={isVideoOn}
								eqpAlarmsEnabled={eqpAlarmsEnabled}
							/>
						)
				)}
			</Grid>
		);
	}

	return (
		<Grid item width={"100%"} container>
			{availableParms.includes("HR") && (
				<ParmDisplay
					parm_name="HR"
					parm={parms.HR}
					connectStatus={connectStatus}
					eqpAlarmsEnabled={eqpAlarmsEnabled}
					fontCorrection={fontCorrection}
				/>
			)}

			{availableParms.includes("SpO2") && (
				<ParmDisplay
					parm_name="SpO2"
					parm={parms.SpO2}
					connectStatus={connectStatus}
					eqpAlarmsEnabled={eqpAlarmsEnabled}
					fontCorrection={fontCorrection}
				/>
			)}

			{availableParms.includes("IBP1") && (
				<ParmDisplay
					parm_name="IBP1"
					parm={parms.IBP1}
					connectStatus={connectStatus}
					eqpAlarmsEnabled={eqpAlarmsEnabled}
					fontCorrection={fontCorrection}
				/>
			)}

			{availableParms.includes("Resp") && (
				<ParmDisplay
					parm_name="Resp"
					parm={parms.Resp}
					connectStatus={connectStatus}
					eqpAlarmsEnabled={eqpAlarmsEnabled}
					fontCorrection={fontCorrection}
				/>
			)}

			{availableParms.includes("RR") && (
				<ParmDisplay
					parm_name="RR"
					parm={parms.Resp}
					connectStatus={connectStatus}
					eqpAlarmsEnabled={eqpAlarmsEnabled}
					fontCorrection={fontCorrection}
				/>
			)}


			{availableParms.includes("EtCO2") && (
				<ParmDisplay
					parm_name="EtCO2"
					parm={parms.EtCO2}
					connectStatus={connectStatus}
					eqpAlarmsEnabled={eqpAlarmsEnabled}
					fontCorrection={fontCorrection}
				/>
			)}

			{availableParms.includes("Temp") && (
				<ParmDisplay
					parm_name="Temp"
					parm={parms.Temp}
					connectStatus={connectStatus}
					eqpAlarmsEnabled={eqpAlarmsEnabled}
					fontCorrection={fontCorrection}
				/>
			)}
		</Grid>
	);
}; // LiveUnitParameters




