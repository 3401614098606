import React, { useState } from 'react';
import { LiveDashboard } from '../../pages/Home/LiveDashboard';
import { VicuTabBar } from './VicuTabBar'; // Adjust the import path as necessary
import { Device } from "../../interfaces/Device";
import { VICU } from "../../interfaces/VICU";
import { useTranslation } from "react-i18next";
import { MapView } from "../../pages/Home/MapView/MapView";
import CustomTabs from "../tabs/CustomTabs";
import { MissionCentral } from '../../pages/Home/MissionCentral';

interface EMSDashboardTabsProps {
    visibleDevices: Device[];
    monitorGroupList: VICU[];
    setSelectedVICU: (vicu: VICU | null) => void;
    selectedVICU: VICU | null;
    updateDeviceList: () => void;
    setDevices: (devices: Device[]) => void;
    useSyspool: boolean;
    organization_id: string;

    regionOptions: { value: string; label: string }[]; // Add the dropdownOptions prop
    regionDropdownTitle: string;
    selectedRegionItem: string | undefined;
    onRegionChange: (value: string) => void; // Add this prop

    filterOptions: { value: number; label: string }[]; // Add the dropdownOptions prop
    filterDropdownTitle: string;
    selectedFilterItem: number | undefined;
    onFilterChange: (value: number) => void; // Add this prop


}

const EMSDashboardTabs: React.FC<EMSDashboardTabsProps> = ({
    organization_id,
    visibleDevices,
    monitorGroupList,
    setSelectedVICU,
    selectedVICU,
    updateDeviceList,
    setDevices,
    useSyspool,
    regionOptions,
    onRegionChange,
    regionDropdownTitle,
    selectedRegionItem,

    filterOptions,
    onFilterChange,
    filterDropdownTitle,
    selectedFilterItem


}) => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [selectedRegion, setSelectedRegion] = useState<string>(
        selectedRegionItem || (regionOptions.length > 0 ? regionOptions[0].value : '')
    );
    const [selectedFilter, setSelectedFilter] = useState<number>(selectedFilterItem || 0);
    const tabNames = [
        t("mission_central"),
        t("live_dashboard"),
        t("mapview")
    ];

    const tabComponents = [
        <MissionCentral organization_id={organization_id} devicelist={visibleDevices} filterValue={selectedFilter}>
            <VicuTabBar
                vicuList={monitorGroupList?.filter((item: any) => item.group_name !== "syspool")}
                activateVicu={setSelectedVICU}
                activeVicu={selectedVICU}
                refreshDevices={updateDeviceList}
            />
        </MissionCentral>,

        <LiveDashboard devicelist={visibleDevices} organization_id={organization_id} filterValue={selectedFilter}>
            <VicuTabBar
                vicuList={monitorGroupList?.filter((item: any) => item.group_name !== "syspool")}
                activateVicu={setSelectedVICU}
                activeVicu={selectedVICU}
                refreshDevices={updateDeviceList}
                
            />
        </LiveDashboard>,

        <MapView devices={visibleDevices} setDevices={setDevices} organization_id={organization_id} filterValue={selectedFilter} >
            <VicuTabBar
                vicuList={monitorGroupList?.filter((item: any) => item.group_name !== "syspool")}
                activateVicu={setSelectedVICU}
                activeVicu={selectedVICU}
                refreshDevices={updateDeviceList}
            />
        </MapView>
    ];

    const handleRegionChange = (value: string) => {
        setSelectedRegion(value);
        onRegionChange(value);
    };

    const handleFilterChange = (value: string) => {
        setSelectedFilter(Number (value));
        onFilterChange(Number (value));
    };


    return (
        <div>
            <CustomTabs
                tabNames={tabNames}
                tabComponents={tabComponents}
                value={currentTab}
                onTabChange={setCurrentTab}

                regionOptions={regionOptions} // Pass dropdownOptions to CustomTabs
                selectedRegion={selectedRegion}
                onRegionChange={handleRegionChange}
                regionDropdownTitle={regionDropdownTitle}

                filterOptions={filterOptions} // Pass dropdownOptions to CustomTabs
                selectedFilter={'' + selectedFilter}
                onFilterChange={handleFilterChange}
                filterDropdownTitle={filterDropdownTitle}

            />


        </div>
    );
};

export default EMSDashboardTabs;
