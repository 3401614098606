import "../../Waveforms/Waveforms.css";
import "../../Waveforms/Waveforms.css";
import "./LiveUnit.css";
import { Grid } from "@mui/material";
//import {  Typography } from "@mui/material";
//import { useState } from "react";
import { useRef, useEffect } from "react";
import { Waveform } from "../../../interfaces/vitals/Waveform";
import { CanvasDims } from "./LiveUnit";
import { makePixels } from "./makepixels";
import { WaveformPixel } from "./makepixels";

const waveDrawOrder = [
  "ECG_Defi",
  "ECG_L1",
  "ECG_L2",
  "ECG_L3",
  "ECG_AVR",
  "ECG_AVL",
  "ECG_AVF",
  "ECG_V",
  "SpO2",
  "Resp",
  "IBP1",
  "IBP2",
  "EtCO2",
  "ACT",
];


const STREAM_BUFFER_MAX_SECONDS = 5; // 40 for Chronolife
const STREAM_BUFFER_MIN_SECONDS = 2;
const STREAM_BUFFER_MAX_MS = STREAM_BUFFER_MAX_SECONDS * 1000;
const STREAM_BUFFER_MIN_MS = STREAM_BUFFER_MIN_SECONDS * 1000;
const PIXELS_PER_SECOND = 80; // screen dimensions : px / sec
const px_per_ms = PIXELS_PER_SECOND / 1000;
const sec_per_px = 1 / PIXELS_PER_SECOND;
const ms_per_px = sec_per_px * 1000;
const NORMAL_SPEED_DELTA = 1.05;
const HIGH_SPEED_DELTA = 1.2; // Speed up by this factor if drawing falls behind incoming buffer pace.
const FILL_WAVEFORM_ZERO_WIDTH = 2; // Width of the zero line for filled waveforms.
const FILL_WAVEFORM_BOTTOM_BORDER = 2; 



export interface LiveWavesProps {
  canvasDims: CanvasDims;
  selectedWaves: string[];
  waveformQueue: React.MutableRefObject<Waveform[][]>;
  queueSize: number; // The size of the queue as a prop
  showLiveWaveforms?: boolean;
  labelpos?: string;
  deviceModel: string;
}



export const LiveUnitWaveforms = ({
  waveformQueue,
  queueSize,
  canvasDims,
  selectedWaves,
  deviceModel,
  showLiveWaveforms = true,
  labelpos = "middle"
}: LiveWavesProps) => {
  //console.log ("LiveUnitWaveforms renders", selectedWaves + " : " + canvasDims.width)
  //console.log ("deviceModel:", deviceModel) 
  const gridCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const waveCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const waveCanvasCtx = useRef<CanvasRenderingContext2D | null>(null);
  const gridCanvasCtx = useRef<CanvasRenderingContext2D | null>(null);
  const pixelBuffer = useRef<waveBufferType>({});
  const selectedWaveforms = useRef<string[]>([]);
  const currentDrawState = useRef<{[name: string]: { prev_x: number; prev_y: number }; }>({});
  const canvasActualDims = useRef<CanvasDims>({ width: 0, height: 0 });
  const parmBoxWidth = useRef<number>(0);
  const BUFFER_MODE = useRef<boolean>(false);
  const prevTime = useRef(0);
  const pixel_speed_delta = useRef(0);
  const fps = 20;
  const fpsInterval = 1000 / fps;
  const pauseWaveforms = useRef(false);

  // This useEffect transposes the total widget dimensions into the actual canvas dims which we use for drawing.
  useEffect(() => {
    // Get the width of the parameter box, specified in CSS. We subtract this from the overall canvas width
    var r = document.querySelector(":root");
    if (r) {
      var rs = getComputedStyle(r);
      const w: string = rs
        .getPropertyValue("--parameterbox-width")
        .replace("px", "");
      parmBoxWidth.current = +w;
      parmBoxWidth.current = 0;
    }

    // Size the canvas.

    if (waveCanvasRef.current) {
      waveCanvasCtx.current = waveCanvasRef.current.getContext("2d", {
        willReadFrequently: true,
      });
      if (waveCanvasCtx.current) {
        waveCanvasCtx.current.canvas.height = canvasDims.height;
        waveCanvasCtx.current.canvas.width = canvasDims.width;
      }
    }
    if (gridCanvasRef.current) {
      gridCanvasCtx.current = gridCanvasRef.current.getContext("2d", {
        willReadFrequently: true,
      });
      if (gridCanvasCtx.current) {
        gridCanvasCtx.current.canvas.height = canvasDims.height;
        gridCanvasCtx.current.canvas.width = canvasDims.width;
      }
    }

    initWaveformGrid(selectedWaves);
    canvasActualDims.current = canvasDims;

    //if (canvasDims !== myDims) {
    //    setMyDims (canvasDims)
    //}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasDims, selectedWaves]);

  const initWaveformGrid = (waveforms: string[]) => {
    // console.log ("InitWaveformGrid  " + waveforms + "   " +  waveforms.length)
    if (waveforms.length === 0) {
      return;
    }

    if (!gridCanvasRef.current || !waveCanvasRef.current || !gridCanvasCtx.current) {
      return;
    }

    // Correct for the dimensions (width, really) of the paramter box
    gridCanvasRef.current.width  = canvasDims.width - parmBoxWidth.current - 1;
    gridCanvasRef.current.height = canvasDims.height - 0;

    waveCanvasRef.current.width = gridCanvasRef.current.width;
    waveCanvasRef.current.height = gridCanvasRef.current.height;

  

    // Draw a rectangle
    //ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    gridCanvasCtx.current.fillStyle = "rgba(0, 255, 255,0.5)";
    gridCanvasCtx.current.fillStyle = "black";
    gridCanvasCtx.current.fillRect(0, 0, gridCanvasCtx.current.canvas.width, gridCanvasCtx.current.canvas.height);

    // console.log ("Width in seconds:", gridCanvasRef.current.width, gridCanvasRef.current.width * sec_per_px);
    // Draw vertical lines every one second
    const display_width = gridCanvasRef.current.width;
    const display_height = gridCanvasRef.current.height;
    const pixels_per_second = PIXELS_PER_SECOND;
    const pixels_per_waveform = display_height / selectedWaves.length;

    gridCanvasCtx.current.strokeStyle = "grey";
    gridCanvasCtx.current.lineWidth = 1;
    for (var x = 0; x < display_width; x += pixels_per_second) {
      gridCanvasCtx.current.beginPath();
      gridCanvasCtx.current.moveTo(x, 0);
      gridCanvasCtx.current.lineTo(x, display_height);
      gridCanvasCtx.current.stroke();
    }

    // Draw horizontal lines between waveforms
    for (var y = 0; y < display_height; y += pixels_per_waveform) {
      gridCanvasCtx.current.beginPath();
      gridCanvasCtx.current.moveTo(0, y);
      gridCanvasCtx.current.lineTo(display_width, y);
      gridCanvasCtx.current.stroke();
    }

    var swimlane_idx = 0;

    waveDrawOrder.forEach((w_name) => {
      const n_waveforms: number = waveforms.length; // Object.keys(pixelBuffer.current).length;

      if (waveforms.includes(w_name) && gridCanvasCtx.current) {
        const SWIMLANE_HEIGHT =
          canvasActualDims.current.height / n_waveforms;
        const swimlane_offset = swimlane_idx * SWIMLANE_HEIGHT;
        const swimlane_center = SWIMLANE_HEIGHT * 0.5;
        const y_center = swimlane_offset + swimlane_center;
        const label_y = labelpos === "middle" ? y_center : y_center - SWIMLANE_HEIGHT / 4;

        // Draw the waveform name
        gridCanvasCtx.current.font = SWIMLANE_HEIGHT > 50 ? "14px Arial" : "12px Arial";
        gridCanvasCtx.current.fillStyle = "#F2F2F2";
        gridCanvasCtx.current.textAlign = "left";
        gridCanvasCtx.current.fillText(w_name, 10, label_y); //- (SWIMLANE_HEIGHT/4));

        swimlane_idx++;
      }
    });
  }; // initWaveformGrid

  useEffect(() => {
    //console.log ("Wave selection changes: ", selectedWaves)
    initWaveformGrid(selectedWaves);

    // Clear out any old data
    waveDrawOrder.forEach((w_name) => {
      pixelBuffer.current[w_name] = [];
      currentDrawState.current[w_name] = { prev_x: -1, prev_y: -999 };
    });

    selectedWaveforms.current = selectedWaves;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWaves]);

  //
  // Ingest new waveforms and transform to pixels.
  //
  useEffect(() => {
    if (pauseWaveforms.current) {
      return;
    }

    while (waveformQueue.current.length > 0) {
      const waves = waveformQueue.current.shift(); // Dequeue a chunk of waveform samples

      //console.log ("waves:", waves)
      if (waves) {

        // Check if we have changed the selected waveforms. If so, reset the drawing to the new waveforms.
        
        const n_waveforms: number = Object.keys(selectedWaveforms.current).length;

        waves.forEach((w) => {
          //console.log ("makePixels wave name:", w)
          const swimlane_height = canvasActualDims.current.height / n_waveforms;
          const pixels_to_add = makePixels(w, PIXELS_PER_SECOND, swimlane_height, deviceModel);

          pixelBuffer.current[w.name] = [...pixelBuffer.current[w.name], ...pixels_to_add];
        });
        
      }
    }
  }, [queueSize, waveformQueue, selectedWaveforms, deviceModel]);




  useEffect(() => {

    // Draw grid
    if (gridCanvasCtx.current != null) {
      initWaveformGrid(selectedWaveforms.current);
    }

    // Start animation to draw the waveforms
    draw_waveforms();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  useEffect(() => {
    //console.log ("ShowLiveWaveforms:", showLiveWaveforms)
    pauseWaveforms.current = !showLiveWaveforms;
    if (!showLiveWaveforms) {
      initWaveformGrid(selectedWaves);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLiveWaveforms, selectedWaves]);





  // This function is called at a certain frames per second to do the drawing work.
  const draw_waveforms = () => {
    requestAnimationFrame(draw_waveforms);

    const now: number = Date.now();
    const elapsed = now - prevTime.current;
    var fast_forward_done = false;

    if (pauseWaveforms.current) {
      return;
    }

    // Calculate how many pixels to draw, and adjust the drawing speed if necessary
    if (elapsed > fpsInterval) {
      const n_pixels_to_draw = Math.floor(
        elapsed * px_per_ms * pixel_speed_delta.current
      );
      prevTime.current = now; // - elapsed;//(elapsed % fpsInterval);

      // Adjust speed according to buffer contents.
      if (selectedWaveforms.current.length > 0) {
        // Compute the maximum buffer length among all selected waveforms
        const bufferLengths = selectedWaveforms.current.map(
          (w) => pixelBuffer.current[w]?.length || 0
        );
        let pixels_remaining = 0;
        if (bufferLengths.length > 0) {
          pixels_remaining = Math.max(...bufferLengths);
        }
        const ms_remaining = pixels_remaining * ms_per_px;
        const sec_remaining = ms_remaining / 1000;

        // If we have a significant backlog of data to be drawn, fast-forward. This can happen if a device
        // sends a long burst of packets after congestion.
        fast_forward_done = false;
        if (sec_remaining > STREAM_BUFFER_MAX_SECONDS) {
          const newBufferLength =
            STREAM_BUFFER_MIN_SECONDS * PIXELS_PER_SECOND;
          pixelBuffer.current = fastForward(
            pixelBuffer.current,
            newBufferLength
          );
          fast_forward_done = true;
        }

        // If we have accumulated too much un-drawn data, speed up a bit to catch up.
        if (ms_remaining > STREAM_BUFFER_MAX_MS) {
          if (pixel_speed_delta.current !== HIGH_SPEED_DELTA) {
            pixel_speed_delta.current = HIGH_SPEED_DELTA;
          }
        }

        // If we are running out of data to plot (we have caught up), slow down to normal speed.
        if (ms_remaining < STREAM_BUFFER_MIN_MS) {
          if (pixel_speed_delta.current !== NORMAL_SPEED_DELTA) {
            pixel_speed_delta.current = NORMAL_SPEED_DELTA;
          }
        }

        if (BUFFER_MODE.current) {
          // Don't draw anything until we have collected the minimum number of samples to draw as dictated by stream_buffer
          const n_msec_target = STREAM_BUFFER_MIN_MS;

          if (ms_remaining >= n_msec_target) {
            // We've buffered enough samples, start drawing again.
            BUFFER_MODE.current = false;
          } else {
            // Keep buffering
            return;
          }
        } else {
          // See if we need to get into buffer mode
          if (pixels_remaining === 0) {
            BUFFER_MODE.current = true;
            return;
          }
        } // BUFFER_MODE
      } // adjust speed

      if ( !waveCanvasCtx.current || !gridCanvasCtx.current) {
        return;
      }

      // Draw the waveform segments in their swimlanes
      const n_waveforms: number = selectedWaveforms.current.length;
      waveCanvasCtx.current.lineWidth = 1.0;
      var swimlane_idx = 1;

      waveDrawOrder.forEach((w_name) => {
        if (selectedWaveforms.current.includes(w_name) && waveCanvasCtx.current && gridCanvasCtx.current) {
          const SWIMLANE_HEIGHT = canvasActualDims.current.height / n_waveforms;
          const swimlane_bottom = canvasActualDims.current.height - (n_waveforms - swimlane_idx) * SWIMLANE_HEIGHT;
          const swimlane_center = SWIMLANE_HEIGHT * 0.5;
          const y_center = swimlane_bottom + swimlane_center;

          const prev_x = currentDrawState.current[w_name].prev_x;
          var prev_y   = currentDrawState.current[w_name].prev_y === -999 ? y_center : currentDrawState.current[w_name].prev_y;

          if (fast_forward_done) {
            // Draw a red line to indicate that something happened here.
            waveCanvasCtx.current.strokeStyle = "grey";
            waveCanvasCtx.current.lineWidth = 2;
            waveCanvasCtx.current.beginPath();
            const offset_gap = 10;
            waveCanvasCtx.current.moveTo(prev_x, offset_gap);
            waveCanvasCtx.current.lineTo(prev_x, waveCanvasCtx.current.canvas.height - offset_gap);
            waveCanvasCtx.current.stroke();

            // Also show a small arrow sign on the top
            waveCanvasCtx.current.font = "16px Arial";
            waveCanvasCtx.current.fillStyle = "grey";
            waveCanvasCtx.current.textAlign = "right";
            waveCanvasCtx.current.fillText(">", prev_x, offset_gap + 10);

            fast_forward_done = false;
          }

          // Prep the context
          //waveCanvasCtx.current.strokeStyle = waveDrawInfo[w_name].color;
          waveCanvasCtx.current.lineWidth = 1.5;

          // Move to the previous pixel
          waveCanvasCtx.current.beginPath();
          waveCanvasCtx.current.moveTo(prev_x, prev_y);

          // Start drawing the pixels
          var draw_x = prev_x;
          var draw_y = y_center;

          const thisBuffer = pixelBuffer.current[w_name];
          var prevDrawnX = prev_x

          for (var px_idx = 0; px_idx < n_pixels_to_draw; px_idx++) {
            // Advance to the next pixel
            draw_x += 1;
            if (draw_x > waveCanvasCtx.current.canvas.width) {
              waveCanvasCtx.current.stroke();
              draw_x = 0;
              waveCanvasCtx.current.beginPath();
              waveCanvasCtx.current.moveTo(0, draw_y);
              prevDrawnX = 0;
            }

            // Get the calculated pixel value
            const y = thisBuffer.shift();

            if (y !== undefined) {

              // Adjust for the swimlane offset
              draw_y = swimlane_bottom - y.value;
              
              const color_y = y.color;

              // If this is an IBP or EtCO2 waveform, draw a vertical line to create a filled waveform look.
              if (w_name === "EtCO2") {
                waveCanvasCtx.current.fillStyle = color_y;

                const fill_width = draw_x - prevDrawnX;

                // makePIxel puts a zero value in the middle of the swimlane, but for these waveforms a zero
                // means the very bottom.
                var fill_y = draw_y - FILL_WAVEFORM_ZERO_WIDTH
                if (w_name === "EtCO2") {
                  fill_y =  draw_y + SWIMLANE_HEIGHT/2 - FILL_WAVEFORM_ZERO_WIDTH;
                }
                const fill_height = fill_y - swimlane_bottom;
                waveCanvasCtx.current.fillRect (prevDrawnX, swimlane_bottom - FILL_WAVEFORM_BOTTOM_BORDER , fill_width, fill_height);
                prevDrawnX = draw_x;
                waveCanvasCtx.current.stroke();

              } else {
                waveCanvasCtx.current.strokeStyle = color_y;
                waveCanvasCtx.current.lineTo(draw_x, draw_y);
              }

              prev_y = draw_y;
            } else {
              draw_y = prev_y;
            } // if not undefined
          }
          waveCanvasCtx.current.stroke();

          currentDrawState.current[w_name].prev_x = draw_x;
          currentDrawState.current[w_name].prev_y = draw_y;

          // Draw a few pixels gap after the last one.
          const PIXEL_GAP = 25;
          waveCanvasCtx.current.fillStyle = "#000000";

          var imgData = gridCanvasCtx.current.getImageData(draw_x, 0, PIXEL_GAP, gridCanvasCtx.current.canvas.height);
          waveCanvasCtx.current.putImageData(imgData, draw_x, 0);

          swimlane_idx++;
        } 
      });
    }
  };

  return (
    <div>
      <Grid item>
        <div style={{ position: "relative" }}>
          <canvas
            ref={gridCanvasRef}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 0,
            }}
          ></canvas>
          <canvas
            ref={waveCanvasRef}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 0,
            }}
          ></canvas>
        </div>
      </Grid>
    </div>
  );
}; // LiveUnitWaveforms


export interface waveBufferType {
  [name: string]: WaveformPixel[];
}

const fastForward = (buffers: waveBufferType, newLength: number): waveBufferType => {
  //console.log ("buffers:", buffers)
  Object.entries(buffers).forEach(([key, value]) => {
    if (buffers[key].length > newLength) {
      buffers[key] = value.slice(-newLength);
    }
    //            console.log(key, value)
  });
  //console.log ("buffers after:", buffers)
  return buffers;
};
