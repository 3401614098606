import { Grid, Box, Typography } from "@mui/material";
import { Device } from "../../interfaces/Device";
import { LiveUnit } from "./Live/LiveUnit";
import "../VicuManagement/VicuManagement.css";
import { ReactNode, useCallback } from "react";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import DeviceService from "../../services/DeviceService";
import { useEffect, useState, useRef } from "react";
import { Loading } from "../../components/Loading";
import { ServerProblem } from "../ReRoute/ServerProblem";
import { sortDevices } from "../../utils/functions/sortDevices"
import { useTranslation } from "react-i18next";
import { HEARTBEAT_GREEN, HEARTBEAT_YELLOW, HEARTBEAT_GREY } from "./MissionCentral";

interface Props {
	devicelist: Device[];
	children?: ReactNode;
    organization_id: string;
	filterValue: number;
}

//const PARMS = ["ECG_L1", "SpO2", "Resp", "IBP1"];

interface parmProps {
	waves: string[];
	parms: string[];
}

export const parmMap: { [id: string]: parmProps } = {
	"Recobro v2": {
		waves: ["ECG_L1", "SpO2", "Resp", "IBP1"],
		parms: ["HR", "Resp", "SpO2", "IBP1"],
	},
	"CL-Vest": {
		waves: ["ECG_L1", "Resp", "ACT"],
		parms: ["HR", "Resp", "Temp"],
	},
	LP1550: { waves: ["ECG_L2"], parms: ["HR", "Resp", "Temp"] },
	LP1251e: { waves: ["ECG_L2", "Resp"], parms: ["HR", "Resp", "Temp"] },
	"c-med alpha": { waves: [], parms: ["HR", "SpO2", "Temp"] },
	TEGO: { waves: [], parms: ["HR", "SpO2", "Temp", "Resp"] },
	TOKO: { waves: ["ECG_L1", "SpO2"], parms: ["HR", "SpO2", "Temp"] },
	"Defigard Touch 7": {
		waves: ["ECG_L1", "ECG_Defi", "SpO2", "IBP1", "EtCO2"],
		parms: ["HR", "Resp", "SpO2", "IBP1", "EtCO2"],
	},
};

export const LiveDashboard = ({ devicelist, organization_id, children, filterValue }: Props) => {
	//console.log("LiveDashboard renders", devicelist);

	/*
	 * Depending on the device type, we show different parameters in the dashboard
	 *
	 * Recobro: HR, SpO2, Resp, IBP1
	 * LifeSignals: HR, RR, Temp
	 * Chronolife: HR, RR, Temp
	 */
    const { t } = useTranslation();
    //const [filterValue, setFilterValue] = useState<number>(Infinity);
	const [cookies, ,] = useCookies(["access_token"]);
	const [sortedDevices, setSortedDevices] = useState <Device []> ([])

	const {
		data: alarmProfiles,
		loading: almLoading,
		error: almError,
	} = useFetch({
		url: DeviceService.getTypes(),
		access_token: cookies.access_token,
	});

    const [activityStatus, setActivityStatus] = useState<{ [key: string]: string }>({});
	const activityStatusRef = useRef<{ [key: string]: string }>({});

	useEffect(() => {
		activityStatusRef.current = activityStatus;
	  }, [activityStatus]);
	
	useEffect(() => {
        if (devicelist.length === 0) {
            setActivityStatus({});
        } else {
            DeviceService.getActivityStatus(cookies.access_token, organization_id, devicelist).then((response: any) => {
                setActivityStatus(response.activity || {});
            });
        }
    }, [devicelist, cookies.access_token, organization_id]);


    const updateActivityStatus = useCallback((device_id: string, timestamp: string) => {
        setActivityStatus((prevStatus) => ({
            ...prevStatus,
            [device_id]: timestamp
        }));
    }, []);


	useEffect (() => {
		setSortedDevices (sortDevices (devicelist))
	}, [devicelist])

    /*
     * Update activity status every second
     */
    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = new Date().getTime();
            setActivityStatus((prevStatus) => {
				const updatedStatus = { ...activityStatusRef.current };
                Object.keys(updatedStatus).forEach((deviceId) => {
                    const activityTime = new Date(updatedStatus[deviceId]).getTime();
                    const timeDiffSeconds = (currentTime - activityTime) / 1000;
                    if (timeDiffSeconds > HEARTBEAT_GREEN && timeDiffSeconds <= HEARTBEAT_YELLOW) {
                        // Update to trigger re-render and change status color to orange
                        updatedStatus[deviceId] = new Date(updatedStatus[deviceId]).toISOString();
                    } else if (timeDiffSeconds > HEARTBEAT_YELLOW && timeDiffSeconds <= HEARTBEAT_GREY) {
                        // Update to trigger re-render and change status color to grey
                        updatedStatus[deviceId] = new Date(updatedStatus[deviceId]).toISOString();
                    } else if (timeDiffSeconds > HEARTBEAT_GREY) {
                        // Update to trigger re-render and change status color to hollow
                        updatedStatus[deviceId] = new Date(updatedStatus[deviceId]).toISOString();
                    }
                });
                return updatedStatus;
            });
        }, 1000); // Check every second

        return () => clearInterval(interval);
    }, []);


	const isDeviceVisible = (device: Device) => {
		const currentTime = new Date().getTime();
		const timestamp = activityStatus[device.device_id];
		if (!timestamp) return filterValue === Infinity;
		const activityTime = new Date(timestamp).getTime();
		const timeDiffSeconds = (currentTime - activityTime) / 1000;
		return timeDiffSeconds <= filterValue;
	};
	  

	if (!alarmProfiles || almLoading) {
		return <Loading />;
	}
	if (almError) {
		return <ServerProblem />;
	}
	//console.log ("alarm profiles:", alarmProfiles)

	return (
		<Box sx={{ flexGrow: 1 }}>
			{children}
			{
				<Grid container direction="row">
					{sortedDevices?.length > 0 ? (
						sortedDevices.map((device, index) => {
						const isVisible = isDeviceVisible(device);
						return (
							<LiveUnit
								key={device.device_id}
								device={device}
								isVisible={isVisible}
								availableWaveforms={parmMap[device.device_model]?.waves || []}
								parameterBox={true}
								header={true}
								minHeight={123}
								availableParameters={parmMap[device.device_model]?.parms || []}
								alarmProp={alarmProfiles}
								showDeviceInfo={false}
								updateActivityStatus={updateActivityStatus}
							/>
						);
						})
					) : (
						<Typography>{t('no_devices_to_show')}</Typography>
					)}
					</Grid>
			}
		</Box>
	);
};
