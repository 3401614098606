import { Device } from "../../../interfaces/Device";
import { MapContainer, TileLayer } from "react-leaflet";
import DeviceMarker from "./DeviceMarker";
import { useEffect, useState, useCallback } from "react";
import { Loading } from "../../../components/Loading";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { isBrowser, isMobile } from "react-device-detect";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import DeviceService from "../../../services/DeviceService";
import { HEARTBEAT_GREEN, HEARTBEAT_YELLOW, HEARTBEAT_GREY } from "../../../pages/Home/MissionCentral";


interface Props {
    devices: Device[];
    organization_id: string;
    setDevices: (devices: Device[]) => void;
    children: ReactNode;
	filterValue: number;
}


export const MapView = ({ devices, organization_id, setDevices, children, filterValue }: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [position, setPosition] = useState<any>(null);
    const [mappedDevices, setMappedDevices] = useState<string[]>([]);
    const [checkedDevices, setCheckedDevices] = useState<string[]>([]);
    const [activityStatus, setActivityStatus] = useState<{ [key: string]: string }>({});
    const [cookies, ,] = useCookies(["access_token"]);

    useEffect(() => {
        if (devices.length === 0) {
            setActivityStatus({});
        } else {
            DeviceService.getActivityStatus(cookies.access_token, organization_id, devices).then((response: any) => {
                setActivityStatus(response.activity || {});
            });
        }
    }, [devices, cookies.access_token, organization_id]);


    useEffect(() => {
        let isMounted = true;
        const arr: string[] = devices?.map((device: Device) => {
            return device.device_id;
        });
        if (isMounted) setMappedDevices(arr);
        return () => {
            isMounted = false;
        };
    }, [devices]);

    /*
     * Update activity status every second
     */
    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = new Date().getTime();
            setActivityStatus((prevStatus) => {
                const updatedStatus = { ...prevStatus };
                Object.keys(updatedStatus).forEach((deviceId) => {
                    const activityTime = new Date(updatedStatus[deviceId]).getTime();
                    const timeDiffSeconds = (currentTime - activityTime) / 1000;
                    if (timeDiffSeconds > HEARTBEAT_GREEN && timeDiffSeconds <= HEARTBEAT_YELLOW) {
                        // Update to trigger re-render and change status color to orange
                        updatedStatus[deviceId] = new Date(updatedStatus[deviceId]).toISOString();
                    } else if (timeDiffSeconds > HEARTBEAT_YELLOW && timeDiffSeconds <= HEARTBEAT_GREY) {
                        // Update to trigger re-render and change status color to grey
                        updatedStatus[deviceId] = new Date(updatedStatus[deviceId]).toISOString();
                    } else if (timeDiffSeconds > HEARTBEAT_GREY) {
                        // Update to trigger re-render and change status color to hollow
                        updatedStatus[deviceId] = new Date(updatedStatus[deviceId]).toISOString();
                    }
                });
                return updatedStatus;
            });
        }, 1000); // Check every second

        return () => clearInterval(interval);
    }, [filterValue]);

    useEffect(() => {
        const successCallback = (position: any) => {
            setPosition([position.coords.latitude, position.coords.longitude]);
        };
        const errorCallback = (error: any) => {
            setPosition([63, 10]);
        };

        if (navigator.geolocation && !position) {
            navigator.geolocation.getCurrentPosition(
                successCallback,
                errorCallback
            );
        }

        const timer = setTimeout(() => {
            setPosition([63, 10]);
        }, 3000);
        return () => {
            clearTimeout(timer);
        };
    }, [position]);

    const filterCheckedDevices = (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => {
        //console.log ("filterDevices", event.target.id, event.target.checked)
        const device_id: string = event.target.id;
        //        const checked   = event.target.checked;

        const tmp = checkedDevices;
        if (checked) {
            if (!checkedDevices.includes(device_id)) {
                tmp.push(device_id);
                setCheckedDevices(tmp);
            }
        } else {
            const index = tmp.indexOf(device_id, 0);
            if (index > -1) {
                tmp.splice(index, 1);
                setCheckedDevices (tmp);
            }
        }
        //console.log (mappedDevices)
    };

    useEffect(() => {
        //console.log ("checekdDevices", checkedDevices)
    }, [checkedDevices]);

    useEffect(() => {
        //console.log ("mappedDevices", mappedDevices)
    }, [mappedDevices]);



    const updateActivityStatus = useCallback((device_id: string, timestamp: string) => {
        setActivityStatus((prevStatus) => ({
            ...prevStatus,
            [device_id]: timestamp
        }));
    }, []);

	const isDeviceVisible = (device: Device) => {
		const currentTime = new Date().getTime();
		const timestamp = activityStatus[device.device_id];
		if (!timestamp) return filterValue === Infinity;
		const activityTime = new Date(timestamp).getTime();
		const timeDiffSeconds = (currentTime - activityTime) / 1000;
		return timeDiffSeconds <= filterValue;
	};

    //console.log ("Mapped devices", mappedDevices)

    if (!position) return <Loading />;

    return (
        <>
            {children}
            {devices?.length > 0 ? (
                <Box
                    sx={{
                        display: "flex",
                        height: "100%",
                        flexDirection: isBrowser ? "row" : "column",
                    }}
                >
                    <Box>
                        <Typography
                            variant="h6"
                            color={theme.palette.text.primary}
                        >
                            {t("select_devices")}
                        </Typography>

                        <FormGroup row={isMobile}>
                            {devices?.map((device: Device) => (
                                isDeviceVisible (device) && <FormControlLabel
                                    sx={{
                                        color: theme.palette.text.primary,
                                    }}
                                    key={device.device_id}
                                    control={
                                        <Checkbox
                                            defaultChecked
                                            id={device.device_id}
                                            onChange={filterCheckedDevices}
                                        />
                                    }
                                    label={
                                        <Typography variant="body2">
                                            {device.nickname ? device.nickname : device.device_id}
                                        </Typography>
                                    }
                                />
                            ))}
                        </FormGroup>
                    </Box>

                    <Box component="main" sx={{ flexGrow: 1, bgcolor: "background.default" }} >
                        <MapContainer
                            style={{ height: "70vh" }}
                            zoom={14}
                            center={position}
                            scrollWheelZoom={true}
                            closePopupOnClick={false}
                        >
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://tile.jawg.io/1a3bb38c-fc14-4a39-91b2-9bf3738d0850/{z}/{x}/{y}{r}.png?access-token=cl4DjZDEwMUCIKkxRgdeFZb4MaERLc8degcELVw9BcapTstY1jN1rlLIrHJHW2w5"
                            />

                            {devices?.map((device: Device) => {
                                return (
                                    <DeviceMarker
                                        key={device.device_id}
                                        device={device}
                                        setDevices={setDevices}  // not used
                                        isVisible={isDeviceVisible(device)}
                                        updateActivityStatus={updateActivityStatus}

                                    />
                                );
                            })}
                        </MapContainer>
                    </Box>
                </Box>
            ) : (
                <Typography>{ t("no_devices_to_show") }</Typography>
            )}
        </>
    );
};
